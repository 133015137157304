import React, { useState } from 'react'
import { Modal, Checkbox, CheckboxGroup, Button } from 'rsuite'
import './scss/cards.scss'
import axiosService from "../../utils/axios.service";
import CurrencyInput from 'react-currency-input-field';
import { event } from 'jquery';
import { optionsTimeCard, optionsHourLast, optionsMinus, optionWaxing } from './constant/TimeUsingCard'
import Select from "react-select";
import { notifications } from "../constant/Notify";
let _ = require('lodash');


const cardTypes = [
	{
		type: 1,
		text: "Thẻ số lần"
	},
	{
		type: 2,
		text: "Thẻ số tiền",
	}
]

const ModalCreateCard = ({ open, setOpen, addPackage,products, listSelectGroup, listGroup }) => {
	const [cardType, setCardType] = useState(1)

	const [productName, setProductName] = useState('')
	const [price, setPrice] = useState(null)
	const [basePrice, setBasePrice] = useState(null)
	const [categoryId, setCategoryId] = useState(null)
	const [description, setDescription] = useState('')
	const [status, setStatus] = useState(1)
	const [type, setType] = useState('')
	const [maxUsed, setMaxUsed] = useState('0')
	const [createCardType, setCreateCardType] = useState('1')
	const [statusEditPrice, setStatusEditPrice] = useState(true)
	const [comServe, setComServe] = useState(0)
	const [useTimeMonth, setUseTimeMonth] = useState('127')
	const [cardAccount, setCardAccount] = useState('')
	const [productNameE, setProductNameE] = useState('')
	const [usedInfinite, setUsedInfinite] = useState(false)
	const [rangeDate, setRangeDate] = useState(0)
	const [timeUsing, setTimeUsing] = useState(30)
	const [productId, setProductId] = useState("")
	const [upgradeId, setUpgradeId] = useState("")
	const [productUsingIds, setProductUsingIds] = useState([])
	const [defaultValueUpgrade, setDefaultValueUpgrade] = useState(null)
	const [defaultProductUsingId, setDefaultProductUsingId] = useState(null)
	const [countVoucherUse, setCountVoucherUse] = useState(null)
	const [typeUsing, setTypeUsing] = useState(false)
	const [typeSell, setTypeSell] = useState(true)
	const [maxUseChild, setMaxUseChild] = useState(false)

	const [lastHour, setLastHour] = useState(optionsHourLast[12].value)
	const [lastMinus, setLastMinus] = useState(optionsMinus[0].value)

	const [giftPackageId, setGiftPackageId] = useState("")
	const [defaultValueGiftPackage, setDefaultValueGiftPackage] = useState(null)
	const [newWaxing, setNewWaxing] = useState({value: 0, label: "Thẻ triệt cũ"})

	var onChangeProductName = (event) => {
		setProductName(event.target.value)
	};

	var onChangeProductNameE = (event) => {
		setProductNameE(event.target.value)
	};


	var onChangePrice = (event) => {
		let dataPrice = event.target.value.replaceAll(",", "");
		setPrice(dataPrice)
	};

	var clickStatusCardType = (val) => {
		setCardType(val)
		setCreateCardType(val)
	};


	var onChangeStatusEditPrice = (event) => {
		setStatusEditPrice(!statusEditPrice)
	};

	const onChangeTypeUsing = (event) => {
		setTypeUsing(!typeUsing)
	};
	var onChangeBasePrice = (event) => {
		let dataBasePrice = event.target.value.replaceAll(",", "");
		setBasePrice(dataBasePrice)
	};


	var onChangeDescription = (event) => {
		setDescription(event.target.value)
	};

	var onMaxUsed = (event) => {
		if (event.target.value >= 999999) {
			setUsedInfinite(true)
		} else {
			setUsedInfinite(false)
		}
		setMaxUsed(event.target.value)
	}

	var onChangeUsedInfinite = (value, check) => {
		setUsedInfinite(check)
		if (check) {
			setMaxUsed(999999)
		} else {
			setMaxUsed(0)
		}
	};

	var onChangeCardAccount = (event) => {
		setCardAccount(event.target.value)
	}

	var onChangeUseTimeMonth = (event) => {
		setUseTimeMonth(event.target.value)
	}

	var onChangeComServe = (event) => {
		setComServe(event.target.value)
	}

	var onChangeTimeCard = (event) => {
		setTimeUsing(event.value)
	}

	const onChangeLastHour = (event) => {
		setLastHour(event.value)
	}

	const onChangeLastMinus = (event) => {
		setLastMinus(event.value)
	}
	const onSelectCategory = (event) => {
		setCategoryId(event.value)
		if (event.value == 5) {
			setNewWaxing(optionWaxing[2])
		} else {
			setNewWaxing(optionWaxing[0])
		}
	}

	const onSelectNewWaxing = (event) => {
		setNewWaxing(event)
	}

	const onChangeUseVoucherCode = (event) => {
		setCountVoucherUse(event.target.value)
	}

	const addNewCart = () => {
		let StatusEditPrice = (statusEditPrice === true) ? 1 : 2
		if (countVoucherUse == 0) {
			setCountVoucherUse(0)
		}
		let type_using = 0
		if (typeUsing) {
			type_using = 1
		}

		const dataServices = {
			"product_name": productName,
			"price": price,
			"base_price": basePrice,
			"category_id": categoryId,
			"description": description,
			"status": status,
			"type": 2,
			"range_date": rangeDate,
			"product_name_e": productNameE,
			"count_voucher_use": countVoucherUse,
			"type_using": type_using,
			"open_sell": (typeSell) ? 0 : 1,
			"max_use_child": (maxUseChild) ? 1 : 0,
			"com": comServe,
			"new_waxing": newWaxing.value ?? 0,
			"meta_object": {
				"max_used": maxUsed,
				"card_type": createCardType,
				"status_edit_price": StatusEditPrice,
				"use_time_month": useTimeMonth,
				"card_account": cardAccount,
				"time_using": timeUsing,
				"product_id": productId,
				"last_hour": lastHour,
				"last_minute": lastMinus,
				"upgrade_id": upgradeId,
				"gift_package": giftPackageId,
				"product_using_ids": productUsingIds
			}
		}

		if (!productName || maxUsed == 0) {
			notifications({
				type: "error",
				mess: "Vui lòng điển đủ thông tin vào ô tô đậm",
			});
			return
		}
		addPackage(dataServices)
	}

	const onSelectProduct = (e) => {
		const value = e ? e.value : ""
		setProductId(value)
	}

	const onSelectUpgrade = (e) => {
		let value = _.map(e, "value")
		setUpgradeId(value)
	}
	const onSelectProductUsingIds = (e) => {
		let value = _.map(e, "value")
		if (!value || value.length === 0) {
			setTypeUsing(false)
			setMaxUseChild(false)
		}
		setProductUsingIds(value)
	}

	const onSelectGiftPackage = (e) => {
		let value = _.map(e, "value")
		setGiftPackageId(value)
	}

	return (
		<>
			<Modal size="lg" open={open} onClose={() => setOpen(!open)}>
				<Modal.Header className="modal-header">
					<Modal.Title>Tạo thẻ dịch vụ</Modal.Title>
				</Modal.Header>

				<Modal.Body className="modal-body add-modal-card">
					<form>
						<div className="form-item-wrapper">
							<div className="flex justify-between">
								<label className="control-label col-3 ng-binding">Tên thẻ</label>
								<input onChange={onChangeProductName} className="form-control required input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Tên thẻ" />
							</div>
							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Tên tiếng anh</label>
								<input onChange={onChangeProductNameE} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Nhập thẻ tên tiếng anh" />
							</div>
							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Mô tả chức năng thẻ</label>
								<textarea onChange={onChangeDescription} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Nhập thẻ tên tiếng anh" />
							</div>
							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Giá bán thẻ</label>
								<div className="flex w-100">
									<CurrencyInput
										className="tr price-input required"
										placeholder="Nhập giá thẻ"
										defaultValue={0}
										groupSeparator={','}
										decimalSeparator={'.'}
										onChange={onChangePrice}
									/>
									<div className="currency">₫</div>

									<div className="flex align-center">
										<Checkbox onChange={onChangeStatusEditPrice}>Sửa giá và số lần thẻ khi thanh toán</Checkbox>
									</div>
								</div>
							</div>
							{/* <div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Màu thẻ</label>
								<div className="flex w-100">
									<CheckboxGroup inline>
										<Checkbox value="blue" className="checkbox-custom checkbox-blue" />
										<Checkbox value="red" className="checkbox-custom checkbox-red" />
										<Checkbox value="green" className="checkbox-custom checkbox-green" />
										<Checkbox value="purple" className="checkbox-custom checkbox-purple" />
										<Checkbox value="light-blue" className="checkbox-custom checkbox-light-blue" />
										<Checkbox value="orange" className="checkbox-custom checkbox-orange" />
									</CheckboxGroup>
								</div>
							</div> */}

							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Loại thẻ</label>
								<div className="flex justify-between w-100">
									{cardTypes.map((item) => (
										<div onClick={() => clickStatusCardType(item.type)} className={`card-type ${cardType === item.type ? 'active' : ''}`} key={item.type}>{item.text}</div>
									))}
								</div>
							</div>
							{cardType == 1 ?
								<div className="flex mt-10 justify-between">
									<label className="control-label col-3 ng-binding">Số lân sử dụng</label>
									<div className="flex w-100">
										<input onChange={onMaxUsed} className="ng-pristine tr price-input required  ng-untouched ng-valid ng-empty" value={maxUsed} />
										<div className="flex align-center">
											<Checkbox onChange={onChangeUsedInfinite} checked={usedInfinite}>Vô hạn</Checkbox>
										</div>
									</div>
								</div>
								:
								<div className="flex mt-10 justify-between">
									<label className="control-label col-3 ng-binding">Số tiền trong thẻ</label>
									<div className="flex w-100">
										<input onChange={onChangeCardAccount} className="tr price-input required ng-pristine ng-untouched ng-valid ng-empty" />
									</div>
								</div>
							}

							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Thời hạn thẻ</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<input onChange={onChangeUseTimeMonth} className="form-control w-20 tr price-input" defaultValue={127} placeholder="127" />
									<span className="input-group-addon ng-binding">tháng từ ngày cấp</span>
								</div>
							</div>
							{cardType == 1 ?
								<div className="flex mt-10 ">
									<label className="control-label col-3 ng-binding">Thời gian làm dịch vụ</label>
									<div className="flex gap-20 item-flex-start w-100 ">
										{optionsTimeCard[0] ? <Select
											isSearchable={false}
											className="select"
											defaultValue={optionsTimeCard[6]}
											options={optionsTimeCard}
											onChange={onChangeTimeCard}
										/> : ""}
									</div>
								</div>
								
								: ""}
							 <div className="flex mt-10">
							 	<label className="control-label col-3 ng-binding">Thời gian đặt lịch muộn nhất</label>
								<div className=" gap-20 item-flex-start w-100">
									<div className="row">
										<div className="col-6">
										<Select className="select" onChange={onChangeLastHour} defaultValue={optionsHourLast[12]} isSearchable={true} options={optionsHourLast} />
										</div>
										<div className="col-6">
										<Select className="select" onChange={onChangeLastMinus} defaultValue={optionsMinus[0]} isSearchable={true} options={optionsMinus} />
										</div>
									</div>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Thời gian dãn cách</label>
								<div className="flex gap-20 item-flex-start w-50 ">
									<input type="number" className="form-control w-50" min={1} value={rangeDate} onChange={(e) => {
										setRangeDate(e.target.value)
									}} />
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Tặng voucher gội đầu</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<input onChange={onChangeUseVoucherCode} className="form-control w-20 tr price-input" defaultValue={0} placeholder="127" />
									<span className="input-group-addon ng-binding">( Config số lần sử dụng )</span>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Buổi lẻ</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										isClearable={true}
										isSearchable={true}
										onChange={onSelectProduct}
										options={products}
									/>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Buổi tặng</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										closeMenuOnSelect={false}
										isClearable={true}
										defaultValue={defaultValueGiftPackage}
										isSearchable={true}
										isMulti
										onChange={onSelectGiftPackage}
										options={products}
									/>
								</div>
							</div>

							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Nhóm dịch vụ</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										isClearable={true}
										isSearchable={true}
										onChange={onSelectCategory}
										options={listSelectGroup}
									/>
								</div>
							</div>

							{categoryId === 5 ? 
								<div className="flex mt-10 ">
									<label className="control-label col-3 ng-binding">Loại thẻ triệt</label>
									<div className="flex gap-20 item-flex-start w-100 ">
										<Select
											className="basic-single w-100"
											classNamePrefix="select"
											isClearable={true}
											isSearchable={true}
											defaultValue={newWaxing}
											onChange={onSelectNewWaxing}
											options={optionWaxing}
										/>
									</div>
								</div>
							: null}

							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Nhóm dịch vụ nâng cấp</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										closeMenuOnSelect={false}
										isClearable={true}
										defaultValue={defaultValueUpgrade}
										isSearchable={true}
										isMulti
										onChange={onSelectUpgrade}
										options={products}
									/>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Thẻ con</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										closeMenuOnSelect={false}
										isClearable={true}
										defaultValue={defaultProductUsingId}
										isSearchable={true}
										isMulti
										onChange={onSelectProductUsingIds}
										options={products}
									/>
								</div>
							</div>
							{productUsingIds.length > 0 ?
								<>
									<div className="flex mt-10 ">
										<label className="control-label col-3 ng-binding">Kiểu sử dụng</label>
										<div className="flex align-center">
											<Checkbox onChange={onChangeTypeUsing}>Sử dụng tính theo lần dịch vụ con</Checkbox>
										</div>
									</div>
									<div className="flex mt-10 ">
										<label className="control-label col-3 ng-binding">Số lần sử dụng của thẻ con</label>
										<div className="flex align-center">
											<Checkbox checked={maxUseChild} onChange={() => setMaxUseChild(!maxUseChild)}>Số lần sử dụng của thẻ con tính theo thẻ cha</Checkbox>
										</div>
									</div>
								</>
							: null}
							
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Mở bán</label>
								<div className="flex align-center">
									<Checkbox checked={typeSell} onChange={() => setTypeSell(!typeSell)}>Mở bán ?</Checkbox>
								</div>
							</div>
						</div>
						<div className="form-item-wrapper">
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Com phục vụ</label>
								<div>
									<div className="flex w-100">
										<input onChange={onChangeComServe} className="form-control w-50 ng-pristine ng-untouched ng-valid ng-empty tr price-input required" defaultValue={0} />
									</div>
									{/* <div className="text-red">
										<Checkbox />
										<label>Tự động tính giá trị 1 lần làm dịch vụ theo giá bán thẻ và số lần sử dụng.</label>
									</div>
									<div className="text-red">
										<Checkbox />
										<label>Tự động giảm hoa hồng theo tỉ lệ giảm giá khi bán thẻ.</label>
									</div> */}
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<Button onClick={() => setOpen(!open)} className="button-renew-card-modal">
						<i className="fa-solid fa-x"></i>
						Đóng
					</Button>
					<Button onClick={() => addNewCart()} className="button-save-card-modal">
						<i className="fa-solid fa-file-lines"></i>
						Lưu thông tin
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalCreateCard