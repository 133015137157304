import React, { useCallback, useState, useLayoutEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap"
import Header from "../Header"
import styles from "../style.module.css"
import { Tag } from "rsuite"
import { format,startOfMonth, endOfMonth } from "date-fns"
import axiosService from "../../../utils/axios.service";
import _ from "lodash"
import async from "async"
import { Line, Column } from '@ant-design/plots';
import { Table, Spin } from "antd"
import getDates from "../getDates"
import { DataGrid } from '@mui/x-data-grid';
import "../report.scss"
import StorageService from "../../../utils/storage.service";
const ReportKtvTvvDetail = () => {
    const columns = [
        { dataIndex: 'users_name', title: 'Nhân viên', width: 150  },
        // { field: 'totalOrder', headerName: 'Số đơn hàng', type: "number", width: 160, styles: { color: "red" } },
        {
            dataIndex: 'total_paid',
            title: 'Thực thu',
            align: "center",
            render: (data) => {
                return (
                    <p className="text4">{convertCurrency(data)}</p>
                )
            },
        },
        {
            dataIndex: 'count_order_tv',
            title: 'HD tư vấn',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        {
            dataIndex: 'count_service',
            title: 'Tổng Dv',
            width: 100 ,
            align: "center",
            render: (data) => {
                return (
                    <p className="text3">{data}</p>
                )
            },
        },
        
        {
            dataIndex: 'aov',
            title: 'AOV',
            align: "center",
            render: (data) => {
                return (
                    <p className="text1">{convertCurrency(data)}</p>
                )
            },
        },
        {
            // dataIndex: 'paidTriet',
            title: 'TT Triệt',
            align: "start",
            width: 200 ,
            render: (data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidTriet)}</p>
                        <p className="text2">Đơn KTV: {data.countTriet}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovTriet)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countDa',
            title: 'TT Da',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidDa)}</p>
                        <p className="text2">Đơn KTV: {data.countDa}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovDa)}</p>
                    </div>
                )
            },
        },
        {
            // dataIndex: 'countKhac',
            title: 'TT Khác',
            align: "start",
            render: (t,data) => {
                return (
                    <div>
                        <p className="text1">Tiền KTV: {convertCurrency(data.paidKhac)}</p>
                        <p className="text2">Đơn KTV: {data.countKhac}</p>
                        <p className="text3">Aov KTV: {convertCurrency(data.aovKhac)}</p>
                    </div>
                )
            },
        },
    ];
    var date = new Date();
    // date.setDate(date.getDate() - 1);

    let oldMonth = date.getFullYear() + '-' + date.getMonth() + '-'
    let day = date.getDate()
    let endOfMonth1 = new Date(endOfMonth(new Date(date)))
    let endOfMonthOld = new Date(endOfMonth(new Date(oldMonth + day)))
    if (day == endOfMonth1.getDate()) {
        day = endOfMonthOld.getDate()
    }
    
    let olDate = new Date(oldMonth + day)

    // console.log(format(new Date(startOfMonth(date)),"yyyy-MM-dd"))
    const [startDate, setStartDate] = useState(format(new Date(startOfMonth(date)),"yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(date), 'yyyy-MM-dd'))
    const [startOldDate, setStartOldDate] = useState(format(new Date(startOfMonth(olDate)),"yyyy-MM-dd"))
    const [endOldDate, setEndOldDate] = useState(format(new Date(olDate), 'yyyy-MM-dd'))

    const [store, setStore] = useState(
        (JSON.parse(StorageService.getItem("user_info")) && JSON.parse(StorageService.getItem("user_info")).role == 3) ?
            JSON.parse(StorageService.getItem("user_info")).stores[0].id
        : parseInt(StorageService.getItem("currentStore")) )
    const [sortBy, setSortBy] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalOrderNew, setTotalOrderNew] = useState(0)
    const [totalOrderTriet, setTotalOrderTriet] = useState(0)
    const [totalOrderDa, setTotalOrderDa] = useState(0)
    const [totalPaidTriet, setTotalPaidTriet] = useState(0)
    const [totalPaidDa, setTotalPaidDa] = useState(0)
    const [totalPaidKhac, setTotalPaidKhac] = useState(0)
    const [ordersByDaysCountBooking, setOrdersByDaysCountBooking] = useState([])
    const [totalKhac, setTotalKhac] =   useState('0%')
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true);
    const [isExport, setIsExport] = useState(false)
    const [listCategoryIds, setSistCategoryIds] = useState([2, 4, 5, 45, 46, 47])
    const [userAdmin, setUserAdmin] = useState(JSON.parse(StorageService.getItem("user_info")))
    const hanldeRangeDate = useCallback((sDate, eDate) => {
        setStartDate(sDate)
        setEndDate(eDate)
        handleOleDate(sDate, eDate)
    }, [startDate, endDate])

    const hanldeStore = useCallback((e) => {
        setStore(e)
    }, [store])
    
    const hanldeRangeDateOld = useCallback((sDate, eDate) => {
        setStartOldDate(sDate)
        setEndOldDate(eDate)
       
    }, [startOldDate, endOldDate])

    const handleOleDate = async (start, end) => {
        start = new Date(start)
        end = new Date(end)
        let oldMonth = start.getFullYear() + '-' + start.getMonth() + '-'
        let olDate = format(new Date(startOfMonth(new Date(oldMonth + start.getDate()))), 'yyyy-MM-dd' )
        let olEndDate = format(new Date(new Date(oldMonth + end.getDate())), 'yyyy-MM-dd' )
        setStartOldDate(olDate)
        setEndOldDate(olEndDate)
    }

    const hanldeSortBy = useCallback((e) => {
        console.log(e)
        setSortBy(e)
    }, [sortBy]);

    useLayoutEffect(() => {
        setLoading(true)
        fetchData(store, endDate, startDate, sortBy, startOldDate, endOldDate)
    }, [store, endDate, startDate, sortBy, startOldDate, endOldDate])
    
    const fetchData = async (s, eD, sD, sBy, startOldDate, endOldDate) => {
        const rs = await axiosService(`api/reports/store/work/ktv-details/${s}?storeId=${s}&startDate=${sD}&endDate=${eD}&startOldDate=${startOldDate}&endOldDate=${endOldDate}&sortBy=${sBy}`, "GET")
        const { data } = rs.data
        if (rs.status == 200) {
            setTotalPaid(data.totalPaid)
            setTotalOrderNew(data.totalOrderNew)
            setTotalOrderTriet(data.totalOrderTriet)
            setTotalOrderDa(data.totalOrderDa)
            setTotalKhac(data.totalKhac)
            setTotalPaidTriet(data.totalPaidTriet)
            setTotalPaidDa(data.totalPaidDa)
            setTotalPaidKhac(data.totalPaidKhac)
            setRows(data.dataTable)

            let rowsCountBooking = []
            let rowsCountBookingSuccess = []
            let rowsCountBookingService = []
            let rowsCountService = []
            for (let item of data.dataTable) {
                let rowCountBooking = {
                    date: item.users_name,
                    value: Number(item.comparePaid),
                    type: 'Tiền thực thu'
                }
                let rowCountBookingSuccess = {
                    date: item.users_name,
                    value: Number(item.compareOrderTv),
                    type: 'Đơn hàng mới'
                }
                let rowCountBookingService = {
                    date: item.users_name,
                    value: Number(item.compareAov),
                    type: 'Aov'
                }
                let rowCountService = {
                    date: item.users_name,
                    value: Number(item.compareService),
                    type: 'Dịch vụ phục vụ'
                }
                rowsCountBooking.push(rowCountBooking)
                rowsCountBookingSuccess.push(rowCountBookingSuccess)
                rowsCountBookingService.push(rowCountBookingService)
                rowsCountService.push(rowCountService)
            }
            setOrdersByDaysCountBooking([...rowsCountBooking, ...rowsCountBookingSuccess, ...rowsCountBookingService])
            let value = "yyyy-MM-dd"
                if (sortBy === 1) {
                    value = "ww"
                } else if (sortBy === 2) {
                    value = "MM"
                }
            setLoading(false)
        }
    }
    function convertCurrency(data) {
        let res = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data)
        return res;
    };

    const config = useMemo(() => configChart(ordersByDaysCountBooking, convertCurrency), [ordersByDaysCountBooking])
    
    const hanldeExportData = () => {
        setIsExport(true)
        setTimeout(() => {
            setIsExport(false)
        }, 2000)
    }
    

    return (
        <div className={`${styles.background} container-fluid report-page`}>
            <Header startDate={startDate} endDate={endDate}
                hanldeRangeDate={hanldeRangeDate} store={store}
                hanldeStore={hanldeStore}
                sortBy={sortBy} hanldeSortBy={hanldeSortBy}
                title={"Lịch đặt theo ngày"} type={"ktv-tvv-detail"}
                csvData={rows} isExport={isExport}
                hanldeExportData={hanldeExportData}
                header={
                    [
                        { label: 'Thời gian', key: "date" },
                        { label: "Số lượng lịch đặt", key: "count_booking" },
                        { label: "Số lượng lịch huỷ", key: "count_close" },
                        { label: "Số lượng lịch thành công", key: "count_success" },
                        { label: "Tỉ lệ huỷ/tổng lịch đặt", key: "proportion" },
                        { label: "Số lượng khách hàng phục vụ", key: "count_customer" },
                        { label: "Lượt dịch vụ", key: "count_service" },
                    ]
                }
                userAdmin={userAdmin}
                startOldDate={startOldDate} endOldDate={endOldDate}
                hanldeRangeDateOld={hanldeRangeDateOld}
            />
              <Spin spinning={loading}>
                <Row className="mt-2">
                    <Col xs={12} md={8}>
                        <Row className="h-100">
                            <Col xs={6} md={3}>
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Tổng tiền thực thu: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <span className={styles.textTag4}>Số lượng hoá đơn:</span>
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {convertCurrency(totalPaid)}</span>
                                            <span className={styles.textTag2}> {totalOrderNew}</span>
                                        </div>
                                    </div>
                                </Tag>
                            </Col>

                            <Col xs={6} md={3} className="pay_type">                   
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Thực thu Triêt: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <span className={styles.textTag4}>Số lượng hoá đơn:</span>
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {convertCurrency(totalPaidTriet)}</span>
                                            <span className={styles.textTag2}> {totalOrderTriet}</span>
                                        </div>
                                    </div>
                                </Tag>
                            </Col>
                            <Col xs={6} md={3} className="pay_type">                   
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Thực thu Da: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <span className={styles.textTag4}>Số lượng hoá đơn:</span>
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {convertCurrency(totalPaidDa)}</span>
                                            <span className={styles.textTag2}> {totalOrderDa}</span>
                                        </div>
                                    </div>
                                </Tag>
                            </Col>
                            <Col xs={6} md={3} className="pay_type">                   
                                <Tag color="blue" className="w-100 p-2 h-100">
                                    <span className={styles.textTag3}>Thục thu dịch vụ Khác: </span>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.textTag}> </span>
                                            <br />
                                            <span className={styles.textTag4}>Số lượng hoá đơn:</span>
                                        </div>
                                        <div className="ml-3">
                                            <span className={styles.textTag}>
                                                {convertCurrency(totalPaidKhac)}</span>
                                            <span className={styles.textTag2}> {totalKhac}</span>
                                        </div>
                                    </div>
                                </Tag>
                            </Col>
                          
                         
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-2" style={{alignItems: 'end'}}>
                    <Col xs={12} md={12}>
                        <div style={{ backgroundColor: "white" }} >
                            <div className="border-bottom mb-1 p-2">
                                <strong className="m-2">Bảng dữ liệu chi tiết</strong>
                            </div>
                            <div style={{ height: 700, width: '100%' }} className="p-2">
                                <Table 
                                    columns={columns} 
                                    dataSource={rows}
                                    pagination={{ pageSize: 5 }}
                                    scroll={{ x: 1200 }}
                                    bordered
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2" style={{alignItems: 'end'}}>
                    <Col xs={12} md={12} className="hide-mobile">
                        <div style={{ backgroundColor: "white" }} className="p-2">
                            <Column {...config} />
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div >

    )
}
const configChart = (data, convertCurrency) => {  
    var config = {
        data: data,
        xField: "date",
        yField: "value",
        seriesField: "type",
        height: 700,
        isStack: true,
        colorField: 'type', // or seriesField in some cases
        xAxis: {
            title: {
              text: "KTV",
              position: "end",
              offset: 0,
              spacing: 8,
              style: {
                fontSize: 13,
                fontWeight: 900,
                textAlign: "start"
              }
            },
            line: {
              style: {
                stroke: "black"
              }
            },
            tickLine: {
              style: {
                stroke: "black"
              }
            },
            label: {
              autoRotate: true,
              style: {
                fill: "black",
              //   fontSize: 10
              },
              formatter: (text) => {
                const oldLabel = text;
                const labelLength = oldLabel.replace(/[^x00-xff]/g, "xx").length;
                let newLabel = "";
                if (labelLength > 12) {
                  let strLen = 0;
                  let firstStr = "";
                  let lastStr = "";
                  for (let i = 0; i < labelLength; i++) {
                    if (oldLabel.charCodeAt(i) > 128) {
                      strLen += 2;
                    } else {
                      strLen++;
                    }
                    if (strLen <= 12) {
                      firstStr += oldLabel.charAt(i);
                    } else {
                      lastStr += oldLabel.charAt(i);
                    }
                  }
                  newLabel = `${firstStr}\n${lastStr}`;
                } else {
                  newLabel = oldLabel;
                }
                return newLabel;
              }
            }
          },
        yAxis: {
            line: {
              style: {
                lineWidth: 2
              }
            },
            label: {
              style: {
                fill: "black"
              },
              offset: 15,
              formatter: (text) => _.round(_.divide(text, 1), 2).toLocaleString()
            }
        },
        color: ["#673ab7", "#2196f3", "#4caf50"],
        label: {
            position: 'middle', // 'top', 'bottom', 'middle'
            formatter: (text) => {
                return text.value + '%';
            },
            style: {
                fontWeight: 600
            }
          
        },
        interactions: [
            {
                type: 'active-region',
                enable: false
            }
        ],
        connectedArea: {
            style: (oldStyle) => {
                return {
                    fill: 'rgba(0,0,0,0.2)',
                    stroke: oldStyle.fill,
                    lineWidth: 1
                };
            }
        },
        legend: {
            position: "right",
            marker: {
              symbol: "square"
            },
            itemName: {
              formatter: (text, item, index) => {
                return '';
              }
            }
          },
      };
    return config
}
export default ReportKtvTvvDetail