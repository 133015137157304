import React, { useState, useEffect, useRef } from "react";
import { axiosGet } from "../../utils/axios.service";
import { TransactionPoup } from "./TransactionPoup"
import { TransactionList } from "./TransactionList"
import { CancelOrder } from "./CancelOrder"
import { PopupConfirmEditOrder } from "./popup/PopupConfirmEditOrder"
import { PopupConfirmReOrder } from "./popup/PopupConfirmReOrder"
import { PopupConfirmUpsaleOrder } from "./popup/PopupConfirmUpsaleOrder"
import { PopupMergeBill } from "./popup/PopupMergeBill"
import { PopupConvertMoney } from "./popup/PopupConvertMoney"
import { ViewBooking } from "./ViewBooking"
import PosOrderItemListUser from "../pos/PosOrderItemListUser";
import Avatar from 'react-avatar';
import { notifications } from "../constant/Notify";
import axiosService from "../../utils/axios.service";
import PrintOrder from './PrintOrder';
import * as convert from "../constant/Convert.helper";
import { differenceInDays, format } from "date-fns";
import socketIOClient from "socket.io-client";
import PopupVoucher from "./popup/PopupVoucher"
import { orderStatus, orderStatusOject, paymentType, orderStatusClass, OwedType } from './constant/OrderConstant';


let _ = require('lodash');
var _props = null

function getEmployeeService(val, key) {
    let listName = [];
    for (let i = 1; i < 5; i++) {
        if (val['employee_' + key + '_name' + i]) listName.push(val['employee_' + key + '_name' + i])
    }

    return listName.join(", ")
}

function convertDateTime(event) {
    let date = new Date(event),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hours = date.getHours(),
        minus = date.getMinutes();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minus < 10) minus = '0' + minus;
    let text = day + '-' + month + '-' + date.getFullYear();
    text = text + ' ' + hours + ':' + minus;
    return text
};

export function OrderDetailRight(props) {
    _props = props
    const socketRef = useRef();
    const [customerOrderCode, setCustomerOrderCode] = useState(null)
    const [customerOrderItems, setCustomerOrderItems] = useState({})
    const [booking, setBooking] = useState(null)
    const [showViewBooking, setShowViewBooking] = useState(false)
    const [showTransaction, setShowTransaction] = useState(false)
    const [actionOrder, setActionOrder] = useState(true)
    const [showTransactionList, setShowTransactionList] = useState(false)
    const [showOrderChangeStatus, setShowOrderChangeStatus] = useState(false)
    const [showOrderPopupConfirm, setShowOrderPopupConfirm] = useState(false)
    const [showOrderPopupReOrder, setShowOrderPopupReOrder] = useState(false)
    const [showOrderPopupUpsale, setShowOrderPopupUpsale] = useState(false)
    const [showPopupMergeBill, setShowPopupMergeBill] = useState(false)
    const [showPopupConvertMoney, setShowPopupConvertMoney] = useState(false)

    const [showCommission, setShowCommission] = useState(Number)
    const [searchOperators, setSearchOperators] = useState("")
    const [editDesc, setEditDesc] = useState(false)
    const [statusEditDesc, setStatusEditDesc] = useState(false)
    const [descOrder, setDescOrder] = useState(props.orderDetail.description);
    const [isCopyText, setIsCopyText] = useState('')
    const [statusVoucher, setStatusVoucher] = useState(false)

    useEffect(() => {
        socketRef.current = socketIOClient(process.env.REACT_APP_API_URL_WE);
        return () => {
            socketRef.current.disconnect();
        };
    }, [])

    const copyText = (text) => {
      setIsCopyText('isCopy')
      navigator.clipboard.writeText(text)
      setTimeout(() => {
        setIsCopyText('')
      }, 1000);
    }

    const requestDateItem = async (item) =>  {
        let id = props.orderDetail.id
        let response = await axiosGet("api/order-list/order-item/" + id);
        if (response.data.code == 200) {
            response.data.data.map((item, key) => {
                let service = {}, consultant = {}
                for (let i = 1; i <= 5; i++) {
                    if (item["employee_service" + i]) service = { ...service, [item["employee_service" + i]]: { id: item["employee_service" + i], name: item["employee_service_name" + i] ?? '' } }
                    if (item["employee_consultant" + i]) consultant = { ...consultant, [item["employee_consultant" + i]]: { id: item["employee_consultant" + i], name: item["employee_consultant_name" + i] ?? '' } }
                }
                response.data.data[key]['service'] = service
                response.data.data[key]['consultant'] = consultant
            })
            if (response.data.data[0] && typeof response.data.data[0] != 'undefined') {
                setActionOrder(response.data.data[0].check_operation)
            }
            setCustomerOrderCode(id)
            setCustomerOrderItems(response.data)
        }
    }

    const requestGetBooking = async (item) =>  {
        let id =  props.orderDetail.id_booking
        if (!id) return setBooking(null)
        let response = await axiosGet("api/bookings/detail/" + id);
        if (response.data.code == 200) {
            setBooking(response.data.data)
        }
    }

    useEffect(() => {
        requestDateItem();
        requestGetBooking();
    }, [props]);

    var showTransactionPoup = (item) => {
        setShowTransaction(true)
    }
    var showTransactionPoupList = (event) => {
        setShowTransactionList(true)
    }

    var hiddenOrderPopupTransactionList = (event) => {
        setShowTransactionList(false)
    }

    var hiddenOrderPopup = (item) => {
        setShowTransaction(false)
    }

    var hiddenOrderChangeStatus = (event) => {
        setShowOrderChangeStatus(false)
    }

    var hiddenShowMergeBill = (event) => {
        setShowPopupMergeBill(false)
    }

    var showChangeStatusOrder = (event) => {
        setShowOrderChangeStatus(true)
    }

    var handleShowPopupEdit = (event) => {
        setShowOrderPopupConfirm(true)
    }

    var handleShowPopupReOrder = (event) => {
        setShowOrderPopupReOrder(true)
    }

    var hiddenOrderChangeStatusReOrder = (event) => {
        setShowOrderPopupReOrder(false)
    }

    var cancelOrder = async (data) => {
        let res = await axiosService("api/order-list/change-status/" + props.orderDetail.id, "PUT", data)
        if (res.data.success == true) {
            props.requestDataOrder(props.orderDetail.id);
            setShowOrderChangeStatus(false)
            notifications({ type: 'success', mess: 'Hủy đơn hàng thành công' });
            socketRef.current?.emit("client-qr-code", {order: props.orderDetail, status: false , store_id: props.orderDetail.stores.id});
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }
    var requestDataOrder = (event) => {
        props.requestDataOrder(props.orderDetail.id);

    }
    var editOrder = async (data) => {
        let res = await axiosService("api/order-list/change-status/" + props.orderDetail.id, "PUT", data)
        if (res.data.success == true) {
            window.location.replace('/pos');
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }

    var upsaleOrder = async (data) => {
        let res = await axiosService("api/order-list/upsale/" + props.orderDetail.id, "PUT", data)
        if (res.data.success == true) {
            window.location.replace('/pos');
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }

    var ReOrder = async (data) => {
        let res = await axiosService("api/re-order/" + props.orderDetail.id, "POST", data)
        if (res.data.success == true) {
            window.location.replace('/pos');
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }

    const handleChangeState = async (id, value) => {
        setShowCommission(id)
    }

    const onChangeDesc = async (event) => {
        setDescOrder(event.target.value);
    }

    const saveDesc = async () => {
        let data = {
            description: descOrder,
            id: props.orderDetail.id
        }
        let res = await axiosService("api/order-list/update-order-desc/", "PUT", data)
        if (res.data.success == true) {
            notifications({ type: 'success', mess: "Sửa ghi chú thành công" });
            setEditDesc(false)
            setStatusEditDesc(true)
        } else {
            notifications({ type: 'error', mess: res.data.message });
        }
    }

    const changeDataCommission = async (type, data, event) => {
        let itemSelected = customerOrderItems;
        let keySelected =  _.findIndex(itemSelected.data, function(o) { return o.id == showCommission; });
        
        let newItemSelected = itemSelected.data[keySelected][type] ?? [];
    
        for (let i = 1; i <= 5; i++) {
            if (type == 'consultant') {
                itemSelected.data[keySelected]["employee_consultant_name" + i] = null
            } else {
                itemSelected.data[keySelected]["employee_service_name" + i] = null
            }
        }

        if (!event.target.className.includes("active")) {
          if (Object.keys(newItemSelected).length < 5)
            newItemSelected = { ...newItemSelected, [data.id]: data };
        } else {
            Object.entries(itemSelected.data[keySelected][type]).map((item, key) => {
                if (item[0] == data.id) delete newItemSelected[data.id];
            });
        }

        var i = 1
        Object.entries(newItemSelected).map((item, key) => { 
            if (type == 'consultant') {
                itemSelected.data[keySelected]["employee_consultant_name" + i] = item[1].name
            } else {
                itemSelected.data[keySelected]["employee_service_name" + i] = item[1].name
            }
            i ++
        });

        itemSelected.data[keySelected][type] = newItemSelected;
         
        const orderItem = await convertOrderItem(itemSelected.data, false)
        let res = await axiosService("api/order-list/update-order-items/" + props.orderDetail.id, "PUT", orderItem)
        if (res.data.data == true) {
            setCustomerOrderItems(itemSelected)
        }

    };

    const convertOrderItem = async (itemSelected, isSave) => {
        let orderItem = [];
        var newItemSelected = []
        for (const [key, value] of Object.entries(itemSelected)) {
            let dataItem = {};

            dataItem = {
                id: value.id,
                quantity: value.quantity,
                price: value.price ?? 0,
                discount: value.discount ?? 0,
                order_id: value.order_id,
                product_name: value.product_name,
                package: value.package,
                package_code: value.package_code,
                note: value.note ?? "",
                product_code: value.product_code ?? value.code,
                product_id: value.product_id,
                type_presenter: value.type_presenter ?? null,
                commission_presenter: value.commission_presenter ?? null,
                presenter_user_id: value.presenter_user_id ?? null,
                presenter_customer_id: value.presenter_customer_id ?? null,
                presenter_name: value.presenter_name ?? null,
                presenter_mobile: value.presenter_mobile ?? null,
                new_package: value.new_package,
                max_used : value.max_used,
                last_used_count: value.last_used_count,
                max_used_package: value.max_used_package,
                note_package: value.note_package,
                amount_deducted_from_membership_card: value.amount_deducted_from_membership_card,
                count_used: value.count_used,
            }
    
            if (value.service && Object.entries(value.service).length > 0) {
                Object.entries(value.service).map((item, key) => {
                    dataItem = {
                    ...dataItem,
                    ["employee_service" + (key + 1)]: item[1].id,
                    ["employee_service_name" + (key + 1)]: item[1].name,
                    }
                })
            }
            if (value.consultant && Object.entries(value.consultant).length > 0) {
              Object.entries(value.consultant).map((item, key) => {
                dataItem = {
                  ...dataItem,
                  ["employee_consultant" + (key + 1)]: item[1].id,
                  ["employee_consultant_name" + (key + 1)]: item[1].name,
                }
              })
            }
            orderItem.push(dataItem)
        }
        return orderItem;
    }

    const handleShowBooking = async (e) => {
        setShowViewBooking(!showViewBooking)
    }

    return (
        <div className="content-order-detail">
            <div className="body-popup">
                <div className="row">
                    <div className="col-12">
                        <div className="customer">
                            <div className="customer-avatar">
                                {props.orderDetail.customers.avata_url ?
                                    <img src={props.orderDetail.customers.avata_url} alt="" />
                                    : <Avatar name={props.orderDetail.customers.full_name} size="50" textSizeRatio={1.75} round="50%" />}

                                <div className="info-customer">
                                    <p>{props.orderDetail.customers.full_name} {props.orderDetail.customers?.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</p>
                                    <p>{props.orderDetail.customers.mobile}</p>
                                </div>
                            </div>
                            <div className="order-status">Mã HD:
                                <span className={orderStatusClass[props.orderDetail.status]}> #{props.orderDetail.order_code}</span>
                                {props.orderDetail.parent_order_id && props.orderDetail.type == 3 ? 
                                    <p>Up/Down:<span style={{background: '#8BC34A'}}>HD{props.orderDetail.parent_order_id} </span></p>
                                    : null
                                }
                                {props.orderDetail.parent_order_id && props.orderDetail.type == 4 ? 
                                    <p>Chuyển đổi:<span style={{background: '#700909'}}>HD{props.orderDetail.parent_order_id} </span></p>
                                    : null
                                }
                            </div>
                        </div>
                        <div className="order-info">

                            <div className="user-order-customer posion-wraper">
                                <div className="user-title">
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Đặt lịch từ: <span>{props.orderDetail.source_from}</span></div>
                                        {booking ? 
                                            <div className="pull-right user-order-date" style={{ backgroundColor: "#fff", color: "#999" }}>Mã đặt lịch: <span onClick={handleShowBooking} style={{ fontSize: "12px", cursor: "pointer" }}>#{booking.book_code}</span></div>
                                        : null}
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Người lập: <span>{props.orderDetail.User ? props.orderDetail.User.name : props.orderDetail.created_name}</span></div>
                                        <div className="pull-right user-order-date">{convertDateTime(props.orderDetail.created_at)}</div>

                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Chỉnh sửa lần cuối: <span>{props.orderDetail.updatedBy ? props.orderDetail.updatedBy.name : props.orderDetail.created_name}</span></div>
                                        <div className="pull-right user-order-date">{convertDateTime(props.orderDetail.updated_at)}</div>

                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Hoàn thành bởi: <span>{props.orderDetail.updatedBy ? props.orderDetail.updatedBy.name : props.orderDetail.created_name}</span></div>
                                        <div className="pull-right user-order-date">{convertDateTime(props.orderDetail.updated_at)}</div>
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Tại cơ sở: <span>{props.orderDetail.stores.name_store}</span></div>
                                    </div>
                                    {props.orderDetail?.transaction.length > 0 ?
                                        props.orderDetail.transaction.map((x,y) => (
                                            x.payment_link_id ? 
                                                <div className="d-inline-block w-100" key={y}>
                                                    <div className="pull-left name-user">
                                                        Mã QR: <a style={{color: "#2196F3 !important"}} href={`${process.env.REACT_APP_QR_LINK}${x.uuid}`} target="_blank"><span>{`${process.env.REACT_APP_QR_LINK}${x.uuid}`} {x.status_pay_os == 1 ? " (Hoàn thành)" : ""}</span></a>
                                                        <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() => copyText(process.env.REACT_APP_QR_LINK + props.orderDetail.uuid)}>Copy</button>
                                                    </div>
                                                </div>
                                            :null
                                        ))
   
                                    : null}
                                </div>
                            </div>
                        </div>

                        <div className="table-list-order-item">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="cl-blue">Dịch vụ</th>
                                        <th className="cl-blue">Số lượng</th>
                                        <th className="cl-blue">Giá</th>
                                        <th className="cl-blue">Thành tiền</th>
                                        <th className="cl-blue">Sửa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerOrderItems.data ?
                                        customerOrderItems.data.map((val, key) => (
                                            <tr key={key} className="order-item-tr">
                                                <td>
                                                    <div className="order-item-detail">
                                                        <div className="item-avatar">
                                                            <img src="https://placehold.co/90x90" alt="" />
                                                            <div className="info-service">
                                                                <p>{!val.new_package && val.price == 0 && val.discount == 0 ? 'Sử dụng' : 'Mua'} {val.product ? val.product.product_name : val.product_name}</p>
                                                                <p>{val.product && val.package_code ? (val.package_code ?? val.product.code) : ''}</p>
                                                            </div>
                                                        </div>
                                                        <div className="employee-order">
                                                            {getEmployeeService(val, "service") ?
                                                                <p>Nhân viên phục vụ: {getEmployeeService(val, "service")}</p>
                                                            : null}
                                                            {getEmployeeService(val, "consultant") ?
                                                                <p>Tư vấn: {getEmployeeService(val, "consultant")}</p>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td style={{ paddingLeft: "20px" }}>{val.quantity}</td>
                                                <td className="cl-blue">
                                                    {convert.convertCurrency(val.price ?? 0)}
                                                </td>
                                                <td className="cl-blue">
                                                    {val.quantity ?
                                                        convert.convertCurrency(val.price * val.quantity - val.discount)
                                                    : '0 đ'}
                                                    
                                                    {val.discount && val.discount ?
                                                        <p className="discount">
                                                            {val.discount && val.discount != 0 ? 
                                                                convert.convertCurrency(val.price * val.quantity)
                                                            : null }
                                                        </p> 
                                                    : null} 
                                                </td>
                                                <td className="text-center has-tooltip-info">
                                                    <div className="require-operator operator_avatar_group ng-scope"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleChangeState(val.id, "commission")}>
                                                        <i className="fas fa-pen"  style={{ color: "#4a90e2" }}></i> 

                                                    </div>
                                                </td>
                                                {showCommission == val.id ? (
                                                    <td className="selected-operator">
                                                        <div className="content-data">
                                                            <div
                                                            className="close"
                                                            onClick={() => handleChangeState("", "commission")}
                                                            >
                                                            ×
                                                            </div>
                                                            <div className="content-operator">
                                                            <div className="head-block">
                                                                <div
                                                                className="select-all"
                                                                // onClick={() => selectAllDataCommission(item)}
                                                                >
                                                                Áp dụng lựa chọn cho toàn bộ đơn hàng
                                                                </div>
                                                                <div className="title-block">Tìm kiếm</div>
                                                                <div className="input-search">
                                                                <input
                                                                    type="text"
                                                                    name="selected-operator"
                                                                    defaultValue={searchOperators}
                                                                    onChange={(e) => setSearchOperators(e.target.value)}
                                                                />
                                                                </div>
                                                            </div>
                                                            <div className="body-operators">
                                                                <div className="top-title">
                                                                <div className="top-name d-flex justify-content-between">Nhân viên<div style={{ cursor: "pointer" }} onClick={props.handleSortName}>
                                                                    {props.isAscOperator ? <i className="fa-solid fa-arrow-down-wide-short mr-4"></i> : <i className="fa-solid fa-arrow-up-wide-short mr-4"></i>}
                                                                </div></div>
                                                                <div className="top-name">Phục vụ</div>
                                                                <div className="top-name">Tư vấn</div>
                                                                </div>
                                                                <div className="list-operator">
                                                                {props.listOperator.map((operator, key) => (
                                                                    <PosOrderItemListUser
                                                                        key={key}
                                                                        operator={operator}
                                                                        changeDataCommission={changeDataCommission}
                                                                        item={val}
                                                                        searchOperators={searchOperators}
                                                                    />
                                                                ))}
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="overlay-user-list"
                                                            onClick={() => handleChangeState("", "commission")}
                                                        ></div>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        )) : null}
                                </tbody>
                            </table>
                        </div>

                        <div className="order-into-money">

                            <div className="user-order-customer posion-wraper">
                                <div className="order-price-type">
                                    <p>Thành tiền</p>
                                    <p className="cl-red">Giảm giá combo</p>
                                    <p className="cl-red">Đã thanh toán</p>
                                    <p className= {props.orderDetail.isDeposit ? "cl-green2"  : "cl-red"}>{props.orderDetail.isDeposit ? "Quà tặng thẻ tiền" : "Thẻ giảm giá/Voucher"}</p>
                                </div>
                                <div className="list-order-price left-view">
                                    <p className="cl-green">{convert.convertCurrency(props.orderDetail.total_price_before ?? 0)}</p>
                                    <p className="cl-red">{convert.convertCurrency(props.orderDetail.discount_by_total_bill ?? 0)}</p>
                                    <p className="cl-red">{convert.convertCurrency(props.orderDetail.paid_amount_before ?? 0)}</p>
                                    <p className={props.orderDetail.isDeposit ? "cl-green2"  : "cl-red"}>
                                        {props.orderDetail.sale_rule_applied_ids ? 
                                            <i className="fas fa-info-circle" onClick={() => {setStatusVoucher(!statusVoucher)}}></i> 
                                        : ""}
                                        {convert.convertCurrency(props.orderDetail.discount_by_rule ?? 0)}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="order-total-money">
                            <div className="user-order-customer  posion-wraper ">
                                <div className="order-price-type">
                                    <p>Tổng tiền</p>
                                    <p>Tiền khách hàng đưa</p>
                                    {props.orderDetail.money_owed > 0 ?
                                        <p className="cl-red">Còn nợ</p>
                                    : null}
                                    {props.orderDetail.transaction ?
                                        props.orderDetail.transaction.map((valTrs, keyTrs) => (
                                            <p key={keyTrs}>{valTrs.pay_type} {valTrs.status == 2 ? <span style={{color: 'red'}}>*</span> : ""}</p>
                                        ))
                                    : null}
                                </div>
                                <div className="list-order-price left-view">
                                    <p className="cl-green">{convert.convertCurrency(props.orderDetail.total_price ?? 0)}</p>

                                    <p className="cl-green">
                                        {props.orderDetail.total_price ?
                                            convert.convertCurrency(Number(props.orderDetail.payByOther)) 
                                        : '0 đ'}
                                    </p>

                                    {props.orderDetail.money_owed > 0 ?
                                        <p className="cl-red blink_me">{convert.convertCurrency(props.orderDetail.money_owed)}</p>
                                    : null}
                                    {props.orderDetail.transaction ?
                                        props.orderDetail.transaction.map((valTrs, keyTrs) => (
                                            <p className="cl-green" key={keyTrs}>{convert.convertCurrency(valTrs.paid_amount)}</p>
                                        ))
                                    : null}
                                </div>
                                <div className="node-order">
                                    <p className="node-title">Ghi chú</p>
                                        {editDesc ? 
                                            <div className="node-text">
                                                <textarea onChange={onChangeDesc} defaultValue={statusEditDesc ? descOrder : props.orderDetail?.description} type="text" />
                                                <div className="button-cent" onClick={saveDesc}>
                                                    <span><i className="fas fa-save"></i></span>Lưu
                                                </div>
                                                <div className="button-cent style3" onClick={() => {setEditDesc(false)}}>
                                                <span><i className="fas fa-trash-alt"></i></span>Hủy
                                                </div>
                                            </div>
                                        :
                                            <p className="node-text">
                                                <span style={{ float: 'left', width: '95%'}}>{statusEditDesc ? descOrder : props.orderDetail?.description}</span>
                                                <i onClick={() => {setEditDesc(true)}} className="fas fa-pen" style={{ color: "#4a90e2", padding: '5px', float: 'right', cursor: 'pointer' }}></i> 
                                            </p>
                                        }
                                </div>
                            </div>
                        </div>
                        {props.orderDetail.status != 2 && props.userAdmin.role != 4 ? 
                            <div className="order-total-checkout-buttons text-right pull-left mt-10 clear">
                                {props.userAdmin.id == 107 ? 
                                    <button onClick={showChangeStatusOrder} className="btn btn-xs bg-danger-400 pull-left mr-5 mb-2 ng-binding ng-scope"><i className="fa fa-trash"></i> Huỷ 123</button>
                                :
                                    props.orderDetail.status != 4 && (actionOrder || props.orderDetail.status == 1) ?
                                        (props.orderDetail.status == 3) ?
                                            (differenceInDays(new Date(), new Date(props.orderDetail.created_at)) <= 3) ? 
                                                <button onClick={showChangeStatusOrder} className="btn btn-xs bg-danger-400 pull-left mr-5 mb-2 ng-binding ng-scope"><i className="fa fa-trash"></i> Hủy</button>
                                            : null
                                        : <button onClick={showChangeStatusOrder} className="btn btn-xs bg-danger-400 pull-left mr-5 mb-2 ng-binding ng-scope"><i className="fa fa-trash"></i> Hủy</button>
                                    : null
                                }
                                

                                {/* // <button className="btn btn-xs bg-danger-400 pull-left mr-5 mb-2 ng-binding ng-scope" ><i className="fa fa-trash"></i> Xoá</button> */}
                                {props.orderDetail.status != 1 ?
                                    <button onClick={showTransactionPoupList} className="btn btn-xs bg-teal-400 mr-5 mb-2 pull-left ng-binding ng-scope" ><i className="fa fa-printer"></i> Lịch sử thanh toán</button>
                                : null}

                                {props.orderDetail.money_owed && ![1, 4, 5].includes(_props.orderDetail.status) ?
                                    <button onClick={showTransactionPoup} className="btn btn-xs bg-teal-400 mr-5 mb-2 pull-left ng-binding ng-scope" ><i className="fa fa-printer"></i> Trả nợ</button>
                                : null}
                                
                                {!_props.orderDetail.isDeposit && ![1, 5].includes(_props.orderDetail.status) ?
                                    props.orderDetail.status != 4 && actionOrder ?
                                        (props.orderDetail.status == 3 && (differenceInDays(new Date(), new Date(props.orderDetail.created_at)) <= 3)) ? 
                                            <button onClick={handleShowPopupEdit} className="btn btn-xs bg-teal-400 pull-left mr-5 mb-2 ng-binding ng-scope" >
                                                <i className="fa fa-pencil"></i> Sửa
                                            </button>
                                        : null
                                    :
                                        (!props.orderDetail.type || props.orderDetail.type != 1) && props.orderDetail.status != 4 && actionOrder ?
                                            <button onClick={handleShowPopupReOrder} className="btn btn-xs bg-teal-400 pull-left mr-5 mb-2 ng-binding ng-scope" >
                                                <i className="fa fa-pencil"></i> Đặt lại đơn hàng
                                            </button>
                                        : null
                                : null}
                                {!_props.orderDetail.isDeposit && ![1, 5].includes(_props.orderDetail.status) ?
                                    props.orderDetail.status != 4 && props.orderDetail.type != 3 && actionOrder ?
                                        <button onClick={() => setShowOrderPopupUpsale(true)} className="btn btn-xs bg-teal-400 pull-left mr-5 mb-2 ng-binding ng-scope" >
                                            <i className="fa fa-pencil"></i> Up & Down Sale
                                        </button>
                                    :
                                    null
                                : null}
                                {!_props.orderDetail.isDeposit && ![1, 5].includes(_props.orderDetail.status) && !_props.orderDetail.is_merge_bill?
                                    props.orderDetail.status != 4 && props.orderDetail.type != 3 && actionOrder && props.orderDetail.type != 4 ?
                                        <button onClick={() => setShowPopupMergeBill(true)} className="btn btn-xs bg-teal-400 pull-left mr-5 mb-2 ng-binding ng-scope" >
                                            <i className="fa fa-pencil"></i> Gộp bill
                                        </button>
                                    :
                                    null
                                : null}
                                {!_props.orderDetail.isDeposit && props.orderDetail.money_owed > 0 && props.orderDetail.status === 3 && actionOrder && (!props.orderDetail.type || props.orderDetail.type === 0) ?
                                    <button onClick={() => setShowPopupConvertMoney(true)} className="btn btn-xs bg-teal-400 pull-left mr-5 mb-2 ng-binding ng-scope" >
                                        <i className="fa fa-pencil"></i> Chuyển đổi về tiền
                                    </button>
                                : null}
                                {customerOrderItems.data && props.orderDetail.status == 3 ? <PrintOrder currentPos={props.orderDetail} orderItem={customerOrderItems.data} /> : null}

                            </div>
                        : null}
                    </div>
                </div>
            </div>
            {showTransaction ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <TransactionPoup
                                customer={props.orderDetail.customers}
                                show={showTransaction}
                                setShow={setShowTransaction}
                            ></TransactionPoup>

                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenOrderPopup} className="button-cent style3">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenOrderPopup} className="overlay"></div>
                    </div>
                </div>
                : null}

            {showTransactionList ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <TransactionList
                                orderDetail={props.orderDetail}
                                show={showTransactionList}
                                setShow={setShowTransactionList}
                            ></TransactionList>

                            <div className="bottom-popup">
                                <div className="button-container">
                                    <div onClick={hiddenOrderPopupTransactionList} className="button-cent style3">
                                        <span><i className="fas fa-times"></i></span>Đóng
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={hiddenOrderPopupTransactionList} className="overlay"></div>
                    </div>
                </div>
                : null}

            {showOrderChangeStatus ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <CancelOrder
                                orderDetail={props.orderDetail}
                                show={showOrderChangeStatus}
                                setShow={hiddenOrderChangeStatus}
                                cancelOrder={cancelOrder}
                            ></CancelOrder>
                        </div>
                        <div onClick={hiddenOrderChangeStatus} className="overlay"></div>
                    </div>
                </div>
                : null}

            {showOrderPopupConfirm ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PopupConfirmEditOrder
                                orderDetail={props.orderDetail}
                                show={showOrderPopupConfirm}
                                setShow={setShowOrderPopupConfirm}
                                orderItem={customerOrderItems.data}
                                editOrder={editOrder}
                            ></PopupConfirmEditOrder>
                        </div>
                        <div onClick={hiddenOrderChangeStatus} className="overlay"></div>
                    </div>
                </div>
            : null}

            {showOrderPopupReOrder ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PopupConfirmReOrder
                                orderDetail={props.orderDetail}
                                show={showOrderPopupReOrder}
                                setShow={setShowOrderPopupReOrder}
                                orderItem={customerOrderItems.data}
                                reOrder={ReOrder}
                            ></PopupConfirmReOrder>
                        </div>
                        <div onClick={hiddenOrderChangeStatusReOrder} className="overlay"></div>
                    </div>
                </div>
            : null}

            {showOrderPopupReOrder ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PopupConfirmReOrder
                                orderDetail={props.orderDetail}
                                show={showOrderPopupReOrder}
                                setShow={setShowOrderPopupReOrder}
                                orderItem={customerOrderItems.data}
                                reOrder={ReOrder}
                            ></PopupConfirmReOrder>
                        </div>
                        <div onClick={hiddenOrderChangeStatusReOrder} className="overlay"></div>
                    </div>
                </div>
            : null}

            {showOrderPopupUpsale ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PopupConfirmUpsaleOrder
                                orderDetail={props.orderDetail}
                                show={showOrderPopupUpsale}
                                setShow={setShowOrderPopupUpsale}
                                upsaleOrder={upsaleOrder}
                                currentStore={props.currentStore}
                            ></PopupConfirmUpsaleOrder>
                        </div>
                        <div onClick={hiddenOrderChangeStatus} className="overlay"></div>
                    </div>
                </div>
            : null}

            {showPopupMergeBill ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PopupMergeBill
                                orderDetail={props.orderDetail}
                                show={showOrderPopupUpsale}
                                setShow={setShowPopupMergeBill}
                                upsaleOrder={upsaleOrder}
                                requestDataOrder= {requestDataOrder}
                                currentStore={props.currentStore}
                            ></PopupMergeBill>
                        </div>
                        <div onClick={hiddenShowMergeBill} className="overlay"></div>
                    </div>
                </div>
            : null}
            {showPopupConvertMoney ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <PopupConvertMoney
                                orderDetail={props.orderDetail}
                                show={showPopupConvertMoney}
                                setShow={setShowPopupConvertMoney}
                                currentStore={props.currentStore}
                                currentNameStore={props.currentNameStore}
                                setDetailOrder={props.setDetailOrder}
                                updateDataOrders={props.updateDataOrders}
                            ></PopupConvertMoney> 
                        </div>
                        <div onClick={() => {setShowPopupConvertMoney(false)}} className="overlay"></div>
                    </div>
                </div>
            : null}

            {showViewBooking && booking ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <div className="content-popup fadeInDown package-popup">
                            <ViewBooking
                                show={showViewBooking}
                                setShow={handleShowBooking}
                                booking= {booking}
                            ></ViewBooking>
                        </div>
                        <div onClick={handleShowBooking} className="overlay"></div>
                    </div>
                </div>
            : null}

            {statusVoucher ?
                <div className="order-popup">
                    <div className="popup-cent">
                        <PopupVoucher
                            orderDetail={props.orderDetail}
                            statusVoucher={statusVoucher}
                            setStatusVoucher={setStatusVoucher}
                            notifications={notifications}
                        ></PopupVoucher>
                        <div onClick={() => {setStatusVoucher(!statusVoucher)}} className="overlay"></div>
                    </div>
                </div>
            : null}
        </div>
    )
}
