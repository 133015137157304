import React from "react";
import { Link } from "react-router-dom";
import StorageService from "../utils/storage.service";
import axiosService from "../utils/axios.service";
import { Dropdown, Row, Col } from 'react-bootstrap';
import { NotificationContainer, } from "react-notifications";
import "../scss/header.scss";
import StoreHeader from "./stores/StoreHeader";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import _ from 'lodash';

class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            stores: [],
            userAdmin: JSON.parse(localStorage.getItem("user_info")),
            checkMobile: false,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ checkMobile: window.innerWidth <= 768 });
    }

    logOut() {
        StorageService.delete("access_token");
        StorageService.delete("user_info");
        window.location.replace("/");
    }

    getDataStores() {

        let currentUrl = window.location.pathname
        if (this.state.userAdmin && this.state.userAdmin.stores.length > 0 && (this.state.userAdmin.role == 4 || (this.state.userAdmin.role == 3 && currentUrl.includes('report')))) {

            this.setState({ stores: this.state.userAdmin.stores })
            let currentStore = StorageService.getItem("currentStore")
            let positionStore = _.findIndex(this.state.userAdmin.stores, function (o) { return o.id == currentStore; });
            if (positionStore < 0) positionStore = 0
            StorageService.set("currentStore", this.state.userAdmin.stores[positionStore].id);
            StorageService.set("currentNameStore", this.state.userAdmin.stores[positionStore].name_store);

        } else {
            axiosService("api/stores", "GET", {}, this.props.token)
                .then((res) => {
                    const response = res.data
                    if (response.success) {
                        if (response.data !== null) {
                            let currentStore = StorageService.getItem("currentStore")
                            if (!currentStore) {
                                StorageService.set("currentStore", response.data[0].id ?? 1);
                                StorageService.set("currentNameStore", response.data[0].name_store ?? "Cent Beauty Trần Duy Hưng");
                            }
                            this.setState({ stores: response.data })
                        }
                    }
                }).catch((error) => console.log(error));
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getDataStores()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header-top">
                        <div className="header-left">
                            {this.props.type !== "crm" ?
                                <>
                                    <div className="menu-item logo">
                                        <Link to={{ pathname: this.state.userAdmin?.role != 2 ? "/dashboard" : "/booking" }}>
                                            <img src="/images/header/logo3.png" alt="logo" />
                                        </Link>
                                    </div>
                                    <div className="menu-item">
                                        <Link to={{ pathname: "/booking" }}>
                                            <i className="snake-gift fa fa-fas fa-calendar-alt"></i>
                                            Lịch hẹn
                                        </Link>
                                    </div>
                                    {/* {this.state.userAdmin && this.state.userAdmin.role != 2 ?
                                        <div className="menu-item">
                                            <Link to={{ pathname: "/pre-order" }}>
                                                <i className="fa fa-light fa-book"></i>
                                                Pre Order
                                            </Link>
                                        </div>
                                    : null} */}
                                    {this.state.userAdmin && this.state.userAdmin?.role != 2 && this.state.userAdmin.role != 4 ?
                                        <div className="menu-item hide-mobile">
                                            <Link to={{ pathname: "/pos" }}>
                                                <i className="fas fa-calculator"></i>
                                                Thu ngân
                                            </Link>
                                        </div>
                                    : null}
                                    {this.state.userAdmin.role != 2 ?
                                        <div className="menu-item hide-mobile">
                                            <Link to={{ pathname: "/customer" }}>
                                                <i className="fas fa-female"></i>
                                                Khách hàng
                                            </Link>
                                        </div>
                                    : null}
                                        <div className="menu-item ">
                                            <Dropdown align={{ lg: 'end' }}>
                                                <Dropdown.Toggle className="button-drop" variant="#fff">
                                                    <i className="fas fa-chart-line"></i>
                                                    Báo cáo
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-report">
                                                    <Row className="p-2 dropdown-menu1">
                                                        {this.state.userAdmin.role != 2 ?
                                                            <Col xs={6} md={3}>
                                                                <div className="border-bottom">
                                                                    <span className="dropText">DOANH THU</span>
                                                                </div>
                                                                <div>
                                                                    <ul className="mt-3 p-0">
                                                                        <Link to={{ pathname: "/orders" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-clipboard-list mr-1"></i><span>Danh sách hoá đơn</span>
                                                                            </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/daily" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-chart-bar mr-1"></i><span>Doanh số theo ngày</span>
                                                                            </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/locations" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-location-dot mr-1"></i><span>Theo chi nhánh</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/service-detail" }}>
                                                                                <li className="text-li p-1">
                                                                                    <i className="fa-solid fa-tags mr-1"></i><span>Tiền thu từng dịch vụ</span>
                                                                                </li>
                                                                            </Link>
                                                                        {/* <Link to={{ pathname: "/report/customers" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-user-large mr-1"></i><span>Theo khách hàng</span>
                                                                            </li>
                                                                        </Link> */}
                                                                        {/* <Link to={{ pathname: "/report/users" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-user-group mr-1"></i><span>Theo nhân viên</span>
                                                                            </li>
                                                                        </Link> */}
                                                                        {/* <Link to={{ pathname: "/report/cashiers" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-calculator mr-1"></i><span>Theo thu ngân</span>
                                                                            </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/products" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Theo sản phẩm/ dịch vụ</span>
                                                                            </li>
                                                                        </Link>*/}
                                                                        {/* <Link to={{ pathname: "/report/sales" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Theo nhân viên đặt lịch</span>
                                                                            </li>
                                                                        </Link> */}
                                                                    </ul>
                                                                </div>
                                                            </Col>
                                                        : null}
                                                        {this.state.userAdmin?.role == 2 || this.state.userAdmin?.role == 1 ?
                                                            <Col xs={this.state.userAdmin?.role == 2 ? 12 : 6} md={this.state.userAdmin?.role == 2 ? 12 : 2} style={{ textAlign: this.state.userAdmin?.role  == 2 ? "center" : "" }}>
                                                                <div className="border-bottom" >
                                                                    <span className="dropText">Chi tiết nhân viên</span>
                                                                </div>
                                                                <div >
                                                                
                                                                    <ul className="mt-3 p-0">
                                                                        
                                                                        <Link to={{ pathname: "/report/detail/ktv-tvv" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết nhân viên</span>
                                                                            </li>
                                                                        </Link>


                                                                    </ul>
                                                                
                                                                </div>
                                                            </Col>
                                                        : null}
                                                      
                                                        {this.state.userAdmin.role != 2 ?
                                                        <Col xs={6} md={2}>
                                                            <div className="border-bottom">
                                                                <span className="dropText">NHÂN VIÊN</span>
                                                            </div>
                                                            <div>
                                                                
                                                                {this.state.userAdmin?.role == 1 ?
                                                                <ul className="mt-3 p-0">
                                                                    
                                                                        <Link to={{ pathname: "/report/online" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo tư vấn Online</span>
                                                                        </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/offline-lt" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo lễ tân</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/offline-ktv-tvv" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Báo cáo tư vấn & phục vụ offline</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/detail/sale-ctm" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết sale</span>
                                                                            </li>
                                                                        </Link>
                                                                </ul>
                                                                : null}
                                                            {this.state.userAdmin?.role == 5 ?
                                                                <ul className="mt-3 p-0">
                                                                 <Link to={{ pathname: "/report/detail/sale-ctm" }}>
                                                                    <li className="text-li p-1">
                                                                        <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết sale</span>
                                                                    </li>
                                                                </Link>
                                                                </ul>
                                                             : null}
                                                            </div>
                                                        </Col>
                                                         : null}
                                                          {this.state.userAdmin.role != 2 ?
                                                        <Col xs={6} md={2}>
                                                            <div className="border-bottom">
                                                                <span className="dropText">Promotions</span>
                                                            </div>
                                                            <div>
                                                                <ul className="mt-3 p-0">
                                                                    <Link to={{ pathname: "/report/promotion" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-clipboard-list mr-1"></i><span>Hiệu quả promotion</span>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </div>
                                                            <div>
                                                                <ul className="mt-3 p-0">
                                                                    <Link to={{ pathname: "/report/detail-promotion" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-clipboard-list mr-1"></i><span>Chi tiết promotion</span>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                          : null}
                                                        {this.state.userAdmin.role != 2 ?
                                                        <Col xs={6} md={3}>
                                                            <div className="border-bottom">
                                                                <span className="dropText">Kinh doanh cơ sở</span>
                                                            </div>
                                                            <div>
                                                                {this.state.userAdmin?.role == 1 ?
                                                                <ul className="mt-3 p-0">

                                                                        <Link to={{ pathname: "/report/operate-booking" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Vận hành cơ sở</span>
                                                                            </li>
                                                                        </Link>
                                                                        <Link to={{ pathname: "/report/business" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Kinh doanh cơ sở</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/ktv-tv-details" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Chi tiết bán hàng KTV</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/ktv-tvv-detail" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Bảng chi tiết từng KTV</span>
                                                                            </li>
                                                                        </Link>

                                                                        <Link to={{ pathname: "/report/total-price-booking" }}>
                                                                            <li className="text-li p-1">
                                                                                <i className="fa-solid fa-tags mr-1"></i><span>Doanh thu dự kiến</span>
                                                                            </li>
                                                                        </Link>
                                                                     
                                                                </ul>
                                                                : null}
                                                            </div>
                                                        </Col>
                                                         : null}
                                                          {/* {this.state.userAdmin.role != 2 ?
                                                        <Col xs={6} md={2}>
                                                            <div className="border-bottom">
                                                                <span className="dropText">TÀI CHÍNH</span>
                                                            </div>
                                                            <div>
                                                                <ul className="mt-3 p-0">
                                                                    <Link to={{ pathname: "/receipt" }}>
                                                                        <li className="text-li p-1">
                                                                            <i className="fa-solid fa-clipboard-list mr-1"></i><span>Doanh thu</span>
                                                                        </li>
                                                                    </Link>
                                                                </ul>
                                                            </div>
                                                        </Col>
                                                         : null} */}
                                                    </Row>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    {this.state.userAdmin.role != 2 ?
                                        <div className="menu-item hide-mobile">
                                            <Link to={{ pathname: "/create-voucher-lv3" }}>
                                                <i className="fas fa-hand-holding"></i>
                                                Lấy voucher
                                            </Link>
                                        </div>
                                    : null}
                                    {this.state.userAdmin.role != 2 ?
                                    <div className="menu-item hide-mobile">
                                        <Link to={{ pathname: "/report/qr-code" }}>
                                            <i className="fas fa-qrcode"></i>
                                            Danh sách QR Code
                                        </Link>
                                    </div>
                                    : null}
                                    <div className="menu-item">
                                        <Link to={{ pathname: "/report/count/package-booking" }}>
                                            <i className="fas fa-qrcode"></i>
                                            Thẻ khách hàng hiện có
                                        </Link>
                                    </div>
                                </>
                            : null}
                        </div>
                        <div className="header-right">
                            {this.props.type != "crm" ? <>
                                <StoreHeader stores={this.state.stores} checkMobile={this.state.checkMobile} />
                                <div className="my-account">
                                    <Dropdown align={{ lg: 'end' }}>
                                        <Dropdown.Toggle className="button-drop" variant="#fff" style={{ "paddingBottom": "0px" }}>
                                            <Avatar size={30} icon={<UserOutlined />} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ left: "auto", right: "0px", position: "absolute" }}>
                                            <Dropdown.Item>
                                                {this.state.userAdmin.name}
                                            </Dropdown.Item>
                                            <Dropdown.Item href={"/change-password"}>
                                                <i className="fas fa-lock"></i><span className="ml-1 mt-2">Đổi mật khẩu</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => { this.logOut() }}>
                                                <i className="fa-solid fa-arrow-right-from-bracket"></i><span className="ml-1 mt-2">Đăng xuất</span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {/* </div> */}
                                </div>
                            </> : ""}
                        </div>
                    </div>
                    <NotificationContainer />
                </div>

            </React.Fragment>
        );
    }
}
export default Header;
