import { useState } from "react";
import { axiosGet } from "../../utils/axios.service";
import { listStatusOrder } from './constant/CustomerBirthday';
import * as convert from "../constant/Convert.helper";
import { formatValue } from 'react-currency-input-field';
import Avatar from 'react-avatar';
import "./scss/customer.scss";
import {orderStatusClass } from '../orders/constant/OrderConstant';


var _props = null
var _state = {}
var isLoading = false

async function requestDateItem() {
    if (_state.setCustomerOrderItems === undefined) return ''
    isLoading = true;
    let id = _props.orderDetail.id
    let response = await axiosGet("api/customer/order-item/" + id);
    if (response.data.code == 200) {
        _state.setCustomerOrderCode(id)
        _state.setCustomerOrderItems(response.data.data)
    }
    isLoading = false;
}

function getEmployeeService(val, key) {
    let listName = [];
    for (let i = 1; i < 5; i++) {
        if (val['employee_'+ key +'_name'+i]) listName.push(val['employee_'+ key +'_name'+i])
    }

    return listName.join(", ");
}

function convertDateTime(event) {
    let date = new Date(event),
        month = date.getMonth() + 1,
        day = date.getDate(),
        hours = date.getHours(),
        minus = date.getMinutes();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minus < 10) minus = '0' + minus;
    let text = day + '-' + month + '-' + date.getFullYear();
    text = text + ' ' + hours + ':' + minus;
    return text
};

export function CustomerOrderPopup(props) {
    const [customerOrderCode, setCustomerOrderCode] = useState(null)
    const [customerOrderItems, setCustomerOrderItems] = useState({})
    const [isCopyText, setIsCopyText] = useState('')

    const copyText = (text) => {
      setIsCopyText('isCopy')
      navigator.clipboard.writeText(text)
      setTimeout(() => {
        setIsCopyText('')
      }, 1000);
    }

    _props = props
    _state.customerOrderCode = customerOrderCode
    _state.setCustomerOrderCode = setCustomerOrderCode

    _state.customerOrderItems = customerOrderItems
    _state.setCustomerOrderItems = setCustomerOrderItems

    let customerDataprop = _props.customer
    if (customerDataprop.id === undefined) return ''
    let orderDetail = _props.orderDetail
    if (orderDetail.id === undefined) return ''
    if (customerOrderCode !== orderDetail.id && !isLoading) requestDateItem()

    return (
        <div>
            <div className="head-popup">
                <div className="title">Chi tiết đơn hàng</div>
                <div className="order-status">Trạng thái:
                    <span className={orderStatusClass[orderDetail.status]} >{listStatusOrder[orderDetail.status]}</span>
                </div>
            </div>
            <div className="body-popup">
                <div className="row">
                    <div className="col-12">
                        <div className="customer">
                            <div className="customer-avatar">
                                {customerDataprop.avata_url ?
                                    <img src={customerDataprop.avata_url} alt="" />
                                    : <Avatar name={customerDataprop.full_name} size="60" textSizeRatio={1.75} round="50%" />}

                                <div className="info-customer">
                                    <p>{customerDataprop.full_name}{customerDataprop.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}</p>
                                    <p>{customerDataprop.mobile}</p>
                                </div>
                            </div>
                            <div className="order-status">Mã HD:
                                <span className={orderStatusClass[orderDetail.status]}>#{orderDetail.order_code}</span>
                            </div>
                        </div>
                        <div className="order-info">
                            <p>Đặt lịch từ: <span>{orderDetail.source_from}</span></p>
                            <div className="user-order-customer posion-wraper">
                                <div className="user-title">
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Người lập: <span>{orderDetail.User ? orderDetail.User.name : orderDetail.createa_name }</span></div>
                                        <div className="pull-right user-order-date">{convertDateTime(orderDetail.created_at)}</div>
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Chỉnh sửa lần cuối: <span>{orderDetail.updatedBy ? orderDetail.updatedBy.name : orderDetail.createa_name}</span></div>
                                        <div className="pull-right user-order-date">{convertDateTime(orderDetail.updated_at)}</div>
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Hoàn thành bởi: <span>{orderDetail.updatedBy ? orderDetail.updatedBy.name : orderDetail.createa_name}</span></div>
                                        <div className="pull-right user-order-date">{convertDateTime(orderDetail.updated_at)}</div>
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="pull-left name-user">Tại cơ sở: <span>{orderDetail.stores.name_store}</span></div>
                                    </div>
                                    {orderDetail?.uuid ?
                                        <div className="d-inline-block w-100">
                                            <div className="pull-left name-user">
                                                Mã QR: <a href={`${process.env.REACT_APP_QR_LINK}${orderDetail.uuid}`} target="_blank"><span>{`${process.env.REACT_APP_QR_LINK}${orderDetail.uuid}`}</span></a>
                                                <button className={isCopyText} style={{marginLeft: '10px'}} onClick={() => copyText(process.env.REACT_APP_QR_LINK + orderDetail.uuid)}>Copy</button>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>

                        <div className="table-list-order-item">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Dịch vụ</th>
                                        <th>Số lượng</th>
                                        <th>Giá</th>
                                        <th>Thành tiền</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_state.customerOrderItems[0] ?
                                        _state.customerOrderItems.map((val, key) => (
                                            <tr key={key} className="order-item-tr">
                                                <td>
                                                    <div className="order-item-detail">
                                                        <div className="item-avatar">
                                                            <img src="https://placehold.co/90x90"/>
                                                            <div className="info-service">
                                                                <p>{!val.new_package && val.price == 0 && val.discount == 0 ? 'Sử dụng' : 'Mua'} {val.product ? val.product.product_name : val.product_name}</p>
                                                                <p>{val.product && val.package_code ? (val.package_code ?? val.product.code) : ''}</p>
                                                            </div>
                                                        </div>
                                                        <div className="employee-order">
                                                            {getEmployeeService(val, "service") ?
                                                                <p>Nhân viên phục vụ: {getEmployeeService(val, "service")}</p>
                                                            : null}
                                                            {getEmployeeService(val, "consultant") ?
                                                                <p>Tư vấn: {getEmployeeService(val, "consultant")}</p>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{val.quantity}</td>
                                                <td className="cl-green">{convert.convertCurrency(val.price ?? 0)}</td>
                                                <td className="cl-green cl-bold">
                                                    {convert.convertCurrency(val.quantity ? val.price * val.quantity - val.discount : 0)} 

                                                    {val.discount && val.discount != 0 ? 
                                                        <p className="discount" style={{textDecoration: "line-through", color: "#777", fontSize: "10px", fontStyle: 'italic'}}>
                                                            {convert.convertCurrency(val.price * val.quantity)}
                                                        </p>
                                                    : null } 
                                                </td>
                                            </tr>
                                        )) : null}
                                </tbody>
                            </table>
                        </div>

                        <div className="order-into-money">

                            <div className="user-order-customer  posion-wraper ">
                                <div className="order-price-type">
                                    <p>Thành tiền</p>
                                    <p className="cl-red">Đã thanh toán</p>
                                    <p className= {orderDetail.isDeposit ? "cl-green2"  : "cl-red"}>{orderDetail.isDeposit ? "Quà tặng thẻ tiền" : "Thẻ giảm giá/Voucher"}</p>
                                </div>
                                <div className="list-order-price left-view">
                                    <p className="cl-green2">{convert.convertCurrency(orderDetail.total_price_before ?? 0)}</p>
                                    <p className="cl-red">{convert.convertCurrency(orderDetail.paid_amount_before ?? 0)}</p>
                                    <p className={orderDetail.isDeposit ? "cl-green"  : "cl-red"}>{convert.convertCurrency(orderDetail.discount_by_rule ?? 0)}</p>
                                </div>
                            </div>

                        </div>

                        <div className="order-total-money">
                            <div className="user-order-customer  posion-wraper ">
                                <div className="order-price-type">
                                    <p>Tổng tiền</p>
                                    <p>Tiền khách hàng đưa</p>
                                    {orderDetail.money_owed > 0 ? 
                                        <p className="cl-red">Còn nợ</p>
                                    : null}

                                   {orderDetail.transaction ?
                                        orderDetail.transaction.map((valTrs, keyTrs) => (
                                            (!valTrs.soft_delete) ? 
                                                <p key={keyTrs}>{valTrs.pay_type} {valTrs.status == 2 ? <span style={{color: 'red'}}>*</span> : ""}</p>
                                            : null
                                        ))
                                     : null}
                                </div>
                                <div className="list-order-price left-view">
                                    <p className="cl-green">{convert.convertCurrency(orderDetail.total_price ?? 0)}</p>

                                    <p className="cl-green">
                                        {convert.convertCurrency(orderDetail.payByOther ? Number(orderDetail.payByOther) : 0)}
                                    </p>
                                    {orderDetail.money_owed > 0 ? 
                                        <p className="cl-red blink_me">
                                            {convert.convertCurrency(orderDetail.money_owed)}
                                        </p> 
                                    : null}

                                   {orderDetail.transaction ?
                                        orderDetail.transaction.map((valTrs, keyTrs) => (
                                            (!valTrs.soft_delete) ? 
                                                <p className="cl-green" key={keyTrs}>{convert.convertCurrency(valTrs.paid_amount)}</p>
                                            :null
                                        ))
                                    : null}
                                </div>
                            </div>
                            {orderDetail.description ?
                                <div className="node-order">
                                    <p className="node-title">Ghi chú</p>
                                    <p className="node-text">{orderDetail.description}</p>
                                </div>
                            : null}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}