import React from "react";
import axiosService from "../../utils/axios.service";
import AddCustomer from "./AddCustomer";
import Delete from "./Delete";
import Loading from "./Loading";
import location from "../../context/vietnam.json";
import Select from 'react-select'

import FilterExportData from "./FilterExportData";
import { EditCredit } from "./EditCredit";
import { EditCredit2 } from "./EditCredit2";
import { HistoryCredit } from "./HistoryCredit"
import { QRCode } from "./QRCode"
import { HistoryTransferPackage } from "./HistoryTransferPackage"
import { ViewImgCustomer } from "./ViewImgCustomer"
import $ from 'jquery';
import { Link } from "react-router-dom";
import Avatar from 'react-avatar';

import "./scss/customer.scss";
import { CustomerRightBar } from "./CustomerRightBar";
import { TransactionPoup } from "../orders/TransactionPoup"
import StorageService from "../../utils/storage.service";
import Add from "../booking/Add"
import { optionsGender, optionsHoursBooking, optionsMinuteBooking } from '../constant/GeneralData'

import { optionsDays, optionsMonths } from "../customer/constant/CustomerBirthday";

import { notifications } from "../constant/Notify";
import * as convert from "../constant/Convert.helper"
import { tabsCustomer } from "./constant/CustomerBirthday"
import { message, Spin, Checkbox } from "antd"
import Header from "../Header";
import _ from "lodash";
const queryParams = new URLSearchParams(window.location.search)
const type = queryParams.get("type")

class Customer extends React.Component {
  constructor(props) {
    super();
    this.myRef = React.createRef()
    this.state = {
      scrollTop: 0,
      selectedFile: null,
      AvataUrl: '',
      AvataName: '',
      token: "",
      AvataS3Name: '',
      srcImg: 'https://placehold.co/90x90',
      isLoaded: false,
      dataCustomer: [],
      totalCustomer: '',
      totalNewCustomer: '',
      countBirthday: '',
      countBirthdayMonth: '',
      detailCustomer: {},
      detailCustomerKey: '',
      filterItems: [],
      showAdd: false,
      showEdit: false,
      showDelete: false,
      editCredit: false,
      historyCredit: false,
      dataHistoryCredit: [],
      listPackage: [],
      editCode: '',
      identifier: '',
      editName: '',
      editEmail: '',
      editIdCard: '',
      editGender: 1,
      editMobile: '',
      editDay: '',
      editMonth: '',
      editYear: '',
      editZaloAccount: '',
      editFacebookId: '',
      editPancakeId: null,
      editJob: '',
      editCompany: '',
      editRanking: '',
      editReferralSource: '',
      editCity: '',
      editDistrict: '',
      editCountry: '',
      editAddress: '',
      editStores: [],
      dataStore: [],
      currentStores: [],
      optionsRanking: [
        { value: 1, label: 'Member' },
        { value: 2, label: 'Silver' },
        { value: 3, label: 'Gold' },
        { value: 4, label: 'Platinum' },
      ],
      optionsDays: optionsDays,
      optionsMonths: optionsMonths,
      list: null,
      page: 1,
      keyword: "",
      totalOwedOrder: 0,
      transactions: [],
      showTransaction: false,
      tabsCustomer: tabsCustomer,
      chooseTab: 0,
      showloading: false,
      click: false,
      showFilterExport: false,
      showLoadingFull: false,
      lastOrder: '',
      keyCity: null,
      keyDistrict: null,
      dataDistrict: [],
      userAdmin: JSON.parse(localStorage.getItem("user_info")),
      typeCredit: 1,
      addBooking: false,
      isNewCustomer: false,
      dataOperators: [],
      dataService: [],
      currentStore: localStorage.getItem("currentStore") ?? 1,
      quickSetUser: '',
      quickSetDate: '',
      disableButtonAdd: false,
      newPhone: '',
      newFullName: '',
      historyTransferPackage: false,
      dataHistoryTransferPackage: [],
      displayQrCode: false,
      dataHeadWashing: 0,
      showImgCustomer: false,
      openSearchCustomer: false,
      widthScreen: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  selectDistrictRef = null;
  customStylesSelect = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      maxHeight: 32
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight = 0;
      const height = 32
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
  };
  customStylesMultiSelect = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      maxHeight: 32
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight = 0;
      const height = 32
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
  };

  showPopupAdd = () => {
    this.setState({ showAdd: true });
  };

  hidePopupAdd = () => {
    this.setState({ showAdd: false });
  };

  showPopupDelete = () => {
    this.setState({ showDelete: true });
  };

  hidePopupDelete = () => {
    this.setState({ showDelete: false });
  };

  showEdit = () => {
    this.setState({ showEdit: true });
  };
  hideEdit = () => {
    this.setState({ showEdit: false });
  };
  convertDateTime = (event, type) => {
    let date = new Date(event),
      month = date.getMonth() + 1,
      day = date.getDate(),
      hours = date.getHours(),
      minus = date.getMinutes();
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (hours < 10) hours = '0' + hours;
    if (minus < 10) minus = '0' + minus;
    let text = day + '-' + month + '-' + date.getFullYear();
    if (type == 'createdAt')
      text = text + ' ' + hours + ':' + minus;
    if (type == 'birthday')
      text = day + '/' + month + '/' + date.getFullYear();

    return text
  };

  getDataTransaction = (id) => {
    axiosService("api/transaction/customer/" + id + '/' + null, "GET")
      .then((res) => {
        if (res.status) {
          if (res.data.data !== null) {
            this.setState({
              totalOwedOrder: res.data.data.totalOwedOrder,
              transactions: res.data.data.data,
              lastOrder: res.data.data.lastOrder,
              dataHeadWashing: res.data.data.dataHeadWashing
            });
          }
        }
      })
  }

  getDetailCustomer = (event, key) => {
    $('.item-customer').removeClass('active');
    $('.item-customer.' + event.id).addClass('active');
    document.body.style.overflow = "auto";
    // let day = this.getDataBirthday(event.Birthday, 'day'),
    // month = this.getDataBirthday(event.Birthday, 'month'),
    // year = (this.getDataBirthday(event.Birthday, 'year'));
    this.getDataTransaction(event.id)
    var storeIds = []
    if (event.stores.length > 0) {
      event.stores.map((item) => {
        storeIds.push(item.id)
      })
    }
    let dataLocation = convert.getDistricts(event.city, event.district)

    this.setState({
      showEdit: false,
      detailCustomer: event,
      detailCustomerKey: key,
      editCode: event.code,
      identifier: event.identifier,
      editName: event.full_name,
      editEmail: event.email,
      editIdCard: event.id_card,
      editGender: event.gender,
      editMobile: event.mobile,
      editZaloAccount: event.zalo_account,
      editFacebookId: event.facebook_id,
      editPancakeId: event.pancake_id,
      editJob: event.job,
      editCompany: event.company,
      editRanking: event.ranking,
      editReferralSource: event.referral_source,
      editCity: event.city,
      editDistrict: event.district,
      editCountry: event.country,
      editAddress: event.address,
      editStores: storeIds,
      editDay: event.day_birthday,
      editMonth: event.month_birthday,
      editYear: event.year_birthday,
      editNote: event.note,
      AvataUrl: event.avata_url,
      AvataName: event.avata_name,
      AvataS3Name: event.avata_s3_name,
      keyCity: (dataLocation && dataLocation.keyCity >= 0) ? dataLocation.keyCity : null,
      keyDistrict: (dataLocation && dataLocation.keyDistrict >= 0) ? dataLocation.keyDistrict : null,
      dataDistrict: (dataLocation && dataLocation.dataDistrict.length >= 0) ? dataLocation.dataDistrict : [],
      isDisabledSave: false,
      openSearchCustomer: false
    });
    this.getCurrentStores(event);
  };
  getDataBirthday = (birthday, type) => {
    let date = new Date(birthday),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      res = '';
    if (day < 10) day = '0' + day;
    if (month < 10) month = '0' + month;
    if (type == 'day') {
      res = day;
    }

    if (type == 'month') {
      res = month;
    }
    if (type == 'year') {
      res = year;
    }

    return res;
  };
  getLabelSelectDropdown = (value, dataOptions) => {
    let label = value;
    if (!value) return  dataOptions[0].label
    for (const key in dataOptions) {
      if (dataOptions[key].value == value)
        label = dataOptions[key].label
    }
    return label
  };
  getCurrentStores = (currentData) => {
    let currentStore = [];
    const storeIds = []
    if (currentData.stores !== null) {
      for (const key in this.state.dataStore) {
        currentData.stores.findIndex(object => {
          if (object.id === this.state.dataStore[key].value)
            currentStore.push(key);
        });
      }
    }
    this.setState({
      currentStores: currentStore
    });
  };
  onChangeEditCode = (event) => {
    this.setState({
      editCode: event.target.value,
    });
  };
  onChangeEditNote = (event) => {
    this.setState({
      editNote: event.target.value,
    });
  };
  onChangeEditName = (event) => {
    this.setState({
      editName: event.target.value
    });
  };
  onChangeEditMobile = (event) => {
    this.setState({
      editMobile: event.target.value,
    });
  };
  onChangeEditRanking = (event) => {
    this.setState({
      editRanking: event.value,
    });
  };
  onChangeEditReferralSource = (event) => {
    this.setState({
      editReferralSource: event.target.value,
    });
  }
  onChangeEditEmail = (event) => {
    this.setState({
      editEmail: event.target.value,
    });
  };
  onChangeEditIdCard = (event) => {
    this.setState({
      editIdCard: event.target.value,
    });
  };
  onChangeEditGender = (event) => {
    this.setState({
      editGender: event.target.value,
    });
  };
  onChangeEditDay = (event) => {
    this.setState({
      editDay: event.value
    });
  };
  onChangeEditMonth = (event) => {
    this.setState({
      editMonth: event.value,
    });
  }
  onChangeEditYear = (event) => {
    this.setState({
      editYear: event.target.value,
    });
  };
  onChangeEditZaloAccount = (event) => {
    this.setState({
      editZaloAccount: event.target.value,
    });
  };
  onChangeEditFacebookId = (event) => {
    this.setState({
      editFacebookId: event.target.value,
    });
  }
  onChangeEditPancakeId = (event) => {
    this.setState({
      editPancakeId: event.target.value,
    });
  }
  onChangeEditJob = (event) => {
    this.setState({
      editJob: event.target.value,
    });
  };
  onChangeEditCompany = (event) => {
    this.setState({
      editCompany: event.target.value,
    });
  }
  onChangeEditCity = (event) => {
    let keyCity = event.value;
    if (keyCity > 0) {
      keyCity = _.findIndex(location, (o) => o.value == keyCity);
    }

    this.setState({
      editCity: event.label,
      dataDistrict: event.districts,
      editDistrict: '',
      keyCity: keyCity,
      keyDistrict: ''
    });
    this.selectDistrictRef.clearValue();
  }
  onChangeEditDistrict = (event) => {
    if (event) {
      let keyDistrict = event.value;
      if (keyDistrict > 0) {
        keyDistrict = _.findIndex(this.state.dataDistrict, (o) => o.value == keyDistrict);
      }

      this.setState({
        keyDistrict: keyDistrict,
        editDistrict: event.label,
      });
    }
  };
  onChangeEditCountry = (event) => {
    this.setState({
      editCountry: event.target.value,
    });
  }
  onChangeEditAddress = (event) => {
    this.setState({
      editAddress: event.target.value,
    });
  };
  onChangeEditStores = (event) => {
    const data = [];
    for (const key in event) {
      data.push(event[key].value)
    }
    this.setState({
      editStores: data,
    });
  };

  hiddenOrderPopup = (event) => {
    this.setState({
      showTransaction: false,
    });
  }

  handelTransaction = (event) => {
    this.setState({
      showTransaction: true,
    });
  }

  hiddenTransaction = (id) => {
    this.setState({
      showTransaction: true,
    });
    this.getDataTransaction(id)
  }

  setShowImgCustomer = (event) => {
    this.setState({
      showImgCustomer: !this.state.showImgCustomer
    })
  }

  setFilterByBirthday = (type) => {
    let date = new Date(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      filterItem = [];

    if (type == 'day') filterItem.push({
      'title': 'Sinh nhật vào',
      'key': 'birthday',
      'value': [
        {
          'key': "day",
          'value': day,
          'label': 'Ngày'
        },
        {
          'key': 'month',
          'value': month,
          'label': 'Tháng'
        }
      ]
    });

    if (type == 'month') filterItem.push({
      'title': 'Sinh nhật vào',
      'key': 'birthday',
      'value': [{
        'key': "month",
        'value': month,
        'label': 'Tháng'
      }]
    });
    this.setState({
      filterItems: filterItem,
      page: 1
    });
    this.filterByData(filterItem)
  }

  onChangeSearchCustomer = _.debounce((event) => {
    let keyword = event.target.value;

    this.setState({
      showloading: true
    })
    axiosService("api/customer?keyword=" + keyword, "GET")
      .then((res) => {
        const response = res.data;
        this.setState({
          dataCustomer: response.data.data ?? [],
          keyword: keyword,
          showloading: false
        })

        // if ( response.data.data &&  response.data.data.length > 0) {
        //   this.getDetailCustomer(response.data.data[0], 0)
        // }
      }).catch((error) => console.log(error));
  }, 1000);

  deleteItemFilter = (keyParent, keyChild) => {
    let itemsFilter = this.state.filterItems;
    itemsFilter.map(function (element, key) {
      if (element.key == keyParent) {
        element.value.map(function (element2, key2) {
          if (element2.key == keyChild) {
            itemsFilter[key].value.splice(key2, 1)
            if (itemsFilter[key].value.length === 0)
              itemsFilter.splice(key, 1)
          }
        })
      }
    })
    this.setState({
      filterItems: itemsFilter,
      page: 1
    });
    this.filterByData(itemsFilter)
  }

  deleteAllFilter = (event) => {
    axiosService("api/customer", "GET")
      .then((res) => {
        const response = res.data
        if (response.success) {
          if (response.data.data !== null) {
            this.setState({
              dataCustomer: response.data.data,
              filterItems: []
            });
            this.getDetailCustomer(response.data.data[0], 0)
          }
        }
      })
  }

  filterByData = (filterItems) => {
    let param = '?';

    filterItems.map(function (element) {
      element.value.map(function (element2) {
        param += element2.key + '=' + element2.value + "&";
      })
    })

    this.setState({
      showloading: true
    })

    axiosService("api/customer" + param, "GET")
      .then((res) => {
        const response = res.data
        if (response.success) {
          this.setState({
            dataCustomer: response.data.data,
            showloading: false
          });
          if (response.data.data)
            this.getDetailCustomer(response.data.data[0], 0)
        }
      })
      .catch((error) => console.log(error));
  }

  addCustomer = async (data) => {
    if (!data.full_name || !data.mobile) {
      notifications({ type: 'error', mess: 'Tên khách hàng và số điện thoại không được trống' });
      this.hanldeClickTrue()
      return
    }
    let res = await axiosService("api/customer", "POST", data);

    this.hanldeClick()
    if (res.data.success == true) {
      let dataCustomer = await this.getDataCustomers();
      this.setState({
        dataCustomer: dataCustomer.data ?? [],
        totalCustomer: dataCustomer.total,
        totalNewCustomer: dataCustomer.itemCountNew,
        detailCustomer: (dataCustomer.data) ? dataCustomer.data[0] : [],
        countBirthday: dataCustomer.total_day_birthday,
        countBirthdayMonth: dataCustomer.total_month_birthday,
      })
      this.hidePopupAdd();
      notifications({ type: 'success', mess: 'Tạo khách hàng mới thành công' });
    } else {
      notifications({ type: 'error', mess: res.data.message });
    }
  };

  saveEditCustomer = async () => {
    this.setState({
      isDisabledSave: true
    })
    let email = this.state.editEmail;
    if (email && email != '' && email.length > 0) {
      email = email.replace(/\s/g, '').toLowerCase();
    }

    let errDate = false
    // if (this.state.editDay == null || this.state.editDay == "") {
    //   errDate = true
    // }
    // if (this.state.editMonth == null || this.state.editMonth == "") {
    //   errDate = true
    // }
    // if (this.state.editYear == null || this.state.editYear == "" || this.state.editYear.length <= 3) {
    //   errDate = true
    // }
    if (!this.state.editName || !this.state.editMobile) {
      notifications({ type: 'error', mess: 'Tên khách hàng và số điện thoại không được trống' });
      this.hanldeClickTrue()
      this.setState({
        isDisabledSave: false
      })
      return
    }
    if (errDate) {
      message.error("Ngày sinh khách hàng không hợp lệ")
      this.hanldeClickTrue()
      this.setState({
        isDisabledSave: false
      })
      return
    } else {
      let birthDay = this.state.editDay + '/' + this.state.editMonth + '/' + this.state.editYear;
      const data = {
        "code": this.state.editCode,
        "full_name": this.state.editName,
        "email": email,
        "id_card": this.state.editIdCard,
        "gender": Number(this.state.editGender),
        "mobile": this.state.editMobile,
        "birthday": birthDay,
        "zalo_account": this.state.editZaloAccount,
        "facebook_id": this.state.editFacebookId,
        "pancake_id": this.state.editPancakeId,
        "job": this.state.editJob,
        "company": this.state.editCompany,
        "ranking": Number(this.state.editRanking),
        "referral_source": this.state.editReferralSource,
        "city": this.state.editCity,
        "district": this.state.editDistrict,
        "address": this.state.editAddress,
        "country": this.state.editCountry,
        "stores": this.state.editStores,
        "avata_url": this.state.AvataUrl,
        "avata_name": this.state.AvataName,
        "avata_s3_name": this.state.AvataS3Name,
        "note": this.state.editNote,
        "identifier": this.state.identifier
      };
      let res = await axiosService("api/customer/" + this.state.detailCustomer.id, "PUT", data);

      if (res.data.success == true) {
        this.setState({
          detailCustomer: res.data.data,
          dataCustomer: this.state.dataCustomer.fill(res.data.data, this.state.detailCustomerKey, this.state.detailCustomerKey + 1)
        })
        this.getCurrentStores(res.data.data)
        this.hideEdit();
        notifications({ type: 'success', mess: 'Cập nhật thông tin mới thành công' });
        this.setState({
          isDisabledSave: false
        })
      } else {
        notifications({ type: 'error', mess: res.data.message });
        this.setState({
          isDisabledSave: false
        })
      }

    }
  }
  deleteCustomer = async () => {
    let res = await axiosService("api/customer/" + this.state.detailCustomer.id, "DELETE")
    if (res.data.success == true) {
      let dataCustomer = await this.getDataCustomers();
      this.setState({
        dataCustomer: dataCustomer.data ?? [],
        totalCustomer: dataCustomer.total,
        totalNewCustomer: dataCustomer.itemCountNew,
        detailCustomer: (dataCustomer.data) ? dataCustomer.data[0] : [],
        countBirthday: dataCustomer.total_day_birthday,
        countBirthdayMonth: dataCustomer.total_month_birthday,
      })
      this.hidePopupDelete();
      notifications({ type: 'success', mess: 'Xóa khách hàng thành công' });
    } else {
      notifications({ type: 'error', mess: res.data.message });
    }
  }

  saveTransaction = async (data) => {
    let res = await axiosService("api/transaction", "POST", data);
    if (res.data.success == true) {
      this.hiddenTransaction(data.customer_id)
      notifications({ type: 'success', mess: 'Trả nợ thành công' });
    } else {
      notifications({ type: 'error', mess: res.data.message });
    }
  }

  getDataCustomers = async () => {
    this.setState({
      showloading: true
    })
    let res = await axiosService("api/customer", "GET", this.state.token)
    if (res.data.success) {
      this.setState({
        showloading: false
      })
    }
    return res.data.data;
  }

  //Credit customer
  handelEditCredit = async (type) => {
    if ([1, 0].includes(type)) {
      this.setState({
        typeCredit: type
      })
    }

    let dataHistoryCredit = await this.getDataCredit();
    let listPackage = await this.getPackageByCustomer(this.state.detailCustomer.id);
    let newPackage = [];
    listPackage.forEach((item) => {
        let left_price = 0
        let price = item.rule_price > 0 ? item.rule_price : item.initial_amount
        if (item.metaPrice && item.metaPrice > 0) {
          left_price = price - (item.metaPrice * item.count_used)
        } else {
          left_price = (price / item.max_used) * (item.max_used - item.count_used)
        }

        let historyUsed = '';
        if (item.max_used <= 15) historyUsed = "(" + item.count_used + "/" + item.max_used + ")";
        let label = item.package_code + " - " + item.product_name + historyUsed;
        item['money_wallet'] = 0
        if (item.transaction_wallet > 0) {
          item['money_wallet'] = Math.round(left_price/item.paid_money_order * item.transaction_wallet)
        }
        left_price = left_price - item['money_wallet']
        if (item.order_id) {
          let paidOrder = 0
          paidOrder = _.sumBy(item.order.transaction, function(o) { return (!o.soft_delete) ? o.paid_amount : 0; });
          if (paidOrder === item.order.total_price) newPackage.push({ ...item, label: label, value: item.package_code, left_price: left_price })
        }
    })

    this.setState({
      dataHistoryCredit: dataHistoryCredit,
      editCredit: !this.state.editCredit,
      listPackage: newPackage,
    });
  }

  handelHistoryCredit = async (type) => {
    let dataHistoryCredit = []
    if (type == 3) {
      dataHistoryCredit = await this.getDataCreditHistoryPackageBhvv();
    } else {
      dataHistoryCredit = await this.getDataCredit(type);
    }
    // const list = dataHistoryCredit.filter(x => {
    //   if (type == 0) {
    //     return x.reason != "Quà tặng"
    //   } else {
    //     return x.reason == "Quà tặng"
    //   }
    // })
    this.setState({
      dataHistoryCredit: [...dataHistoryCredit],
      historyCredit: !this.state.historyCredit,
    });
  }

  getDataCredit = async (type) => {
    let dataHistoryCredit = await axiosService("api/credit/" + this.state.detailCustomer.id + "?type=" + type, "GET");
    if (dataHistoryCredit.data.success !== true) return []

    return dataHistoryCredit.data.data
  }

  getDataCreditHistoryPackageBhvv = async () => {
    let dataHistoryCredit = await axiosService("api/credit/history-package/" + this.state.detailCustomer.id, "GET");
    if (dataHistoryCredit.data.success !== true) return []

    return dataHistoryCredit.data.data
  }

  handelHistoryTransferPackage = async (type) => {
    let dataHistoryTransferPackage = await this.getDataTransferPackage();
    this.setState({
      dataHistoryTransferPackage: [...dataHistoryTransferPackage],
      historyTransferPackage: !this.state.historyTransferPackage,
    });
  }

  getDataTransferPackage = async () => {
    
    let dataHistoryCredit = await axiosService("api/package/transfer/" + this.state.detailCustomer.id, "GET");
    if (dataHistoryCredit.data.success !== true) return []
  
    return dataHistoryCredit.data.data
  }

  changeCurrenDeposit = (newDeposit, giftMoney) => {
    let dataCus = { ...this.state.detailCustomer }
    if (!giftMoney && typeof giftMoney != 'undefined') {
      giftMoney = dataCus.gift_money
    }
    
    dataCus = {
      ...dataCus,
      deposit_money: newDeposit,
      gift_money: giftMoney
    }
    
    this.setState({
      detailCustomer: { ...dataCus },
      dataCustomer: this.state.dataCustomer.fill({ ...dataCus }, this.state.detailCustomerKey, this.state.detailCustomerKey + 1)
    })
  }

  changeCurrenAvatar = (dataImage) => {
    let dataCus = { ...this.state.detailCustomer }
  
    dataCus = {
      ...dataCus,
      avata_url: dataImage
    }
    
    this.setState({
      detailCustomer: { ...dataCus },
      dataCustomer: this.state.dataCustomer.fill({ ...dataCus }, this.state.detailCustomerKey, this.state.detailCustomerKey + 1)
    })
  }

  getPackageByCustomer = async (id) => {
    let response = await axiosService("api/package/customer/" + id, "GET");
    if (response.data.success !== true) return
    let listPackage = response.data.data.listPackage

    return listPackage
  }

  onScroll = () => {
    const clientHeight = this.myRef.current.clientHeight
    const scrollTop = this.myRef.current.scrollTop
    const scrollHeight = this.myRef.current.scrollHeight
    let param = '&';

    if (clientHeight + scrollTop == scrollHeight) {
      this.setState({
        showloading: true
      })
      var page = this.state.page + 1
      let filterItems = this.state.filterItems
      filterItems.map(function (element) {
        element.value.map(function (element2) {
          param += element2.key + '=' + element2.value + "&";
        })
      })

      axiosService("api/customer?page=" + page + "&keyword=" + this.state.keyword + param, "GET")
        .then((res) => {
          const response = res.data
          if (response.code == 200) {
            if (response.data !== null) {
              if (response.data.data.length > 0) {
                this.setState({
                  dataCustomer: this.state.dataCustomer.concat(response.data.data),
                  page: page,
                });
                // this.getDetailCustomer(response.data.data[0], 0)
              }
            }
          }
          this.setState({
            showloading: false
          })
        })
    }

    this.setState({
      scrollTop: scrollTop,
    })
  }

  onClickExportCustomer = (e) => {
    this.setState({
      showFilterExport: true
    })
  }

  hideFilterExport = (e) => {
    this.setState({
      showFilterExport: false
    })
  }

  handleExportDataCustomer = (data) => {
    this.setState({
      showFilterExport: false,
      showLoadingFull: true
    })

    let param = "?startOrderAt=" + data.startOrderAt + "&endOrderAt=" + data.endOrderAt + "&startCustomer=" + data.startCustomer + "&endCustomer=" + data.endCustomer
    if (data.birthDay) param += "&birthDay=" + data.birthDay
    if (data.birthMonth) param += "&birthMonth=" + data.birthMonth
    if (data.birthYear) param += "&birthYear=" + data.birthYear
    if (data.ranking) param += "&ranking=" + data.ranking
    axiosService("api/export/customer" + param, "GET")
      .then((res) => {
        const response = res.data
        if (response) {
          window.location.href = response
          this.setState({
            showLoadingFull: false
          })
        }
      })
  }

  checkBirthday = (day, month) => {
    let today = new Date();
    let birthday = [("0" + day).slice(-2), ("0" + month).slice(-2)].join('-');

    today = [convert.getDay(today), convert.getMonth(today)].join('-');

    if (today == birthday) {
      return true;
    } else {
      return false
    }
  }
  hanldeChooseTab = (i) => {
    tabsCustomer.forEach((x) => {
      x.selected = false
    })
    tabsCustomer[i].selected = true
    this.setState({
      tabsCustomer: [...tabsCustomer],
      chooseTab: i
    })
  }
  hanldeClick = () => {
    this.setState({
      click: !this.state.click
    })
  }
  hanldeClickTrue = () => {
    this.setState({
      click: false
    })
  }
  checkUserInfor = async (email) => {
    try {
      const res = await axiosService(`api/get-infor?email=${email}`)
      return res
    } catch (error) {
      console.error(error)
    }
  }

  hidenPopupDelete = async () => {
    this.setState({
      showDelete: false
    })
  }
  setCustomerData = async (data) => {
    let customer = this.state.detailCustomer
    customer.deposit_money = Number(data)
    this.setState({
      detailCustomer: {...customer}
    })
  }

  getDataOperators = async () => {
    try {
      let selectDataOperators = []
      const response = await axiosService("api/users/store/" + this.state.currentStore, "GET");
      if (response.data.data !== null) {
        for (const key in response.data.data) {
          selectDataOperators.push({
            value: response.data.data[key].id,
            label: response.data.data[key].name,
            image: response.data.data[key].avatar_url,
          })
        }
        return selectDataOperators;
      }
    } catch (error) {
      console.log(error)
    }
  }

  getDataServices = async () => {
    try {
      let selectDataService = []
      const response = await axiosService("api/product", "GET");
      if (response.data.data.data !== null) {
        for (const key in response.data.data.data) {
          selectDataService.push({
            value: response.data.data.data[key].id,
            label: response.data.data.data[key].product_name,
            time: Number(response.data.data.data[key].meta_object.hour * 60) + Number(response.data.data.data[key].meta_object.minute),
            price: Number(response.data.data.data[key].price),
            category_id: Number(response.data.data.data[key].category_id)
          })
        }

        return selectDataService;
      }
    } catch (error) {
      return console.log(error);
    }
  }

  getServiceByCustomer = async (id) => {
    let listPackage = await axiosService("api/package/customer/" + id+"/booking", "GET");

    listPackage = listPackage.data.data.listPackage;
    if (listPackage.length > 0) {
      listPackage.map((item, key) => {
        if (item.status === 1) {
          let used = (item.max_used >= 99) ? "Không giới hạn" : (item.max_used - item.count_used) + " lần";
          let label = item.type === "Thẻ lần" ? item.product_name  + " (" + used + ")" : item.product_name;
          listPackage[key] = {
            ...item,
            "label": label,
            "product_name": item.product_name,
            "price": 0,
            "time": 0,
            "value": item.package_code ?? item.product_id,
            "product_id": item.product_id,
            "package_code": item.package_code,
            "left_use": item.max_used - item.count_used,

          }
        }
      })
    }
    return listPackage;
  };

  handlePopupAddBooking = async () => {
    if (!this.state.addBooking) {
      let dataOperator = await this.getDataOperators()
      let dataService = await this.getDataServices()
      this.setState({
        dataOperators: dataOperator,
        dataService: dataService
      })
    }
    this.setState({
      addBooking: !this.state.addBooking
    })
  }

  addBooking = async (data) => {
    let res = await axiosService("api/bookings", "POST", data)
    if (res.data.success == true) {
      notifications({ type: 'success', mess: 'Tạo lịch mới thành công' });
      this.setState({
        addBooking: false,
        disableButtonAdd: false
      })
    } else {
      notifications({ type: 'error', mess: res.data.message });
      this.setState({
        disableButtonAdd: false
      })
    }
  }

  handleButtonBooking = () => {
    this.setState({
      disableButtonAdd: true
    })

  }

  onFocusInputSearch = () => {
    if (this.state.widthScreen <= 1199 ) {
      document.body.style.overflow = "hidden";
      this.setState({ openSearchCustomer: true })
    }
  }

  onChangeIdentifier = (e) => {
    this.setState({
      identifier: e.target.checked ? 1 : 0,
    })
  }

  updateWindowDimensions() {
    this.setState({ widthScreen: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    if (type == "crm") {
      const phone = queryParams.get("phone")
      const email = queryParams.get("email")
      this.checkUserInfor(email).then(rs => {
        const { data } = rs
        if (data.code === 200 && data.data != null) {
          this.setState({
            userAdmin: data.data.user,
            token: data.data.token
          })
          StorageService.set("user_info", JSON.stringify(data.data.user));
          StorageService.set("access_token", data.data.token);
          const getDefaultCustomer = async () => {
            return this.getDataCustomers();
          }
          const getDataStores = async () => {
            return await axiosService("api/stores", "GET", data.data.token)
              .then((res) => {
                const response = res.data
                const selectDataStore = []
                if (response.code === 200) {
                  if (response.data !== null) {
                    for (const key in response.data) {
                      selectDataStore.push({
                        value: response.data[key].id,
                        label: response.data[key].name_store
                      })
                    }

                  }
                  return selectDataStore;
                }
              }).catch((error) => console.log(error));

          }

          Promise.all([getDefaultCustomer(), getDataStores()]).then((results) => {
            this.setState({
              isLoaded: true,
              dataCustomer: results[0].data ?? [],
              totalCustomer: results[0].total,
              totalNewCustomer: results[0].itemCountNew,
              countBirthday: results[0].total_day_birthday,
              countBirthdayMonth: results[0].total_month_birthday,
              dataStore: results[1] ?? [],
            });
            if (results[0].data)
              this.getDetailCustomer(results[0].data[0], 0)
            axiosService("api/customer?keyword=" + phone, "GET")
              .then((res) => {
                const response = res.data;
                this.setState({
                  dataCustomer: response.data.data ?? [],
                  keyword: phone,
                  showloading: false,
                  detailCustomer: (response.data) ? response.data.data[0] : [],
                })

                // if ( response.data.data &&  response.data.data.length > 0) {
                //   this.getDetailCustomer(response.data.data[0], 0)
                // }
              }).catch((error) => console.log(error));
          });
        } else if (data.code == 404) {
          return window.location.replace('/')
        }
      })
    } else {
      if (!this.state.userAdmin) window.location.replace("/");
      const getDefaultCustomer = async () => {
        return this.getDataCustomers();
      }
      const getDataStores = async () => {
        return await axiosService("api/stores", "GET")
          .then((res) => {
            const response = res.data

            const selectDataStore = []
            if (response.code === 200) {
              if (response.data !== null) {
                for (const key in response.data) {
                  selectDataStore.push({
                    value: response.data[key].id,
                    label: response.data[key].name_store
                  })
                }
              }
              return selectDataStore;
            }
          }).catch((error) => console.log(error));

      }

      Promise.all([getDefaultCustomer(), getDataStores()]).then((results) => {
        this.setState({
          isLoaded: true,
          dataCustomer: results[0].data ?? [],
          totalCustomer: results[0].total,
          totalNewCustomer: results[0].itemCountNew,
          detailCustomer: (results[0].data) ? results[0].data[0] : [],
          countBirthday: results[0].total_day_birthday,
          countBirthdayMonth: results[0].total_month_birthday,
          dataStore: results[1] ?? [],
        });
        if (results[0].data)
          this.getDetailCustomer(results[0].data[0], 0)
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  render() {
    if (this.state.isLoaded) {
      return (
        <div>
          {/* {type != "crm" && <Header type={type} token={this.state.token} />} */}
          <div className="admin-page customer">
            <Loading show={this.state.showLoadingFull}></Loading>
            <div className="page-title">
              <div className="title-page">
                <i className="fas fa-female"></i> Khách hàng
              </div>
              {type != "crm" && this.state.userAdmin && this.state.userAdmin.role != 4 ?
                <div className="button-cent" onClick={this.showPopupAdd}>
                  <span>
                    <i className="fas fa-user-plus"></i>
                  </span>
                  Thêm mới
                </div>
                : null}
            </div>

            {type != "crm" &&
              <div className="top-body">
                <div className="item">
                  Tổng số khách hàng <b>{this.state.totalCustomer}</b>
                </div>
                <div className="item">
                  Khách hàng mới hôm nay <b>{this.state.totalNewCustomer}</b>
                </div>
                <div className="item">
                  <div className="filter" onClick={() => this.setFilterByBirthday('day')}>Khách hàng sinh nhật hôm nay <b>{this.state.countBirthday}</b></div>
                </div>
                <div className="item">
                  <div className="filter" onClick={() => this.setFilterByBirthday('month')}>Khách hàng sinh nhật tháng này <b>{this.state.countBirthdayMonth}</b></div>
                </div>
              </div>
            }
            <div className="admin-body">
              <div className="dashboard-content">
                <div className={`${type == "crm" ? "wrapperCrm" : "wrapper"}`} >
                  <div className="fixed-column list-customers">
                    <div className="header-col">
                      <div className="title">Tìm kiếm</div>
                      <div className="action">
                        <div className="search-form">
                          <i className="fas fa-search"></i>
                          <input
                            className="search"
                            onChange={this.onChangeSearchCustomer}
                            type="text"
                            defaultValue={this.state.keyword}
                            placeholder="Tìm kiếm khách hàng"
                            onFocus={this.onFocusInputSearch} onBlur={this.onBlurInputSearch}
                          />
                        </div>
                        <div className="download-data" onClick={this.onClickExportCustomer}>
                          <i className="fas fa-download"></i>
                        </div>
                      </div>
                    </div>
                    {/* <Loading
                    show={this.state.showloading}
                  ></Loading> */}
                    <div className="body-col" ref={this.myRef}
                      onScroll={this.onScroll}>
                      <Spin spinning={this.state.showloading}>
                        {this.state.filterItems.length > 0 ?
                          <div className="filter-block">
                            {this.state.filterItems.map((element, index) => (
                              <div className="filter-head" key={index}>
                                <label>{element.title}:</label>
                                {element.value.map((element2, index2) => (
                                  <span className="tag tag-with-remove" onClick={() => { this.deleteItemFilter(element.key, element2.key) }} key={index2}>{element2.label} {element2.value} <i className="fas fa-times"></i></span>
                                ))}
                              </div>
                            ))}
                            <div className="action-filter">{this.state.dataCustomer !== null ? this.state.dataCustomer.length : "0"} kết quả <span className="action-delete" onClick={this.deleteAllFilter}>Xóa tìm kiếm</span></div>
                          </div>
                        : null}
                        {this.state.widthScreen >= 1200 || this.state.openSearchCustomer ?
                          <div>
                            <div className="customer-block-list">
                              {this.state.dataCustomer.length > 0 ?
                                this.state.dataCustomer.map((val, key) => (
                                  <div
                                    className={key == 0 ? 'item-customer active ' + val.id : 'item-customer ' + val.id}
                                    key={key}
                                    onClick={() => { this.getDetailCustomer(val, key) }}>
                                    <div className="avatar">
                                      {val.avata_url ?
                                        <img src={val.avata_url} alt="" />
                                        : <Avatar name={val.full_name} size="36" textSizeRatio={1.75} round="50%" />}
                                    </div>
                                    <div className="info">
                                      <div className="name">
                                        {val.full_name}
                                        {val.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                                        {this.checkBirthday(val.day_birthday, val.month_birthday) === true ? <i className="fas fa-gifts snake-gift"></i> : null}
                                      </div>
                                      <div className="phone">{val.mobile}</div>
                                      <div className="address">{val.district} - {val.city}</div>
                                    </div>
                                  </div>
                                ))
                              : 
                                <div className="empty-customer">Không có kết quả phù hợp</div>
                              }
                            </div>
                            {this.state.openSearchCustomer ?
                              <div className="overlay-search-customer" onClick={() => {this.setState({ openSearchCustomer: false }); document.body.style.overflow = "auto";}}></div>
                            : null}
                          </div>
                        : null}
                      </Spin>
                    </div>
                  </div>
                  <div className="fixed-column detail-customer">
                    <div className="header-col">
                      <div className="title">Thông tin khách hàng</div>
                      {type != "crm" && this.state.userAdmin && this.state.userAdmin.role != 4 && Object.keys(this.state.detailCustomer).length !== 0 ?
                        this.state.showEdit == false ?
                          <div className="action">
                            <div className="button-cent one-col" onClick={() => {this.setState({ displayQrCode: true })}}>
                              <span>
                                <i className="fas fa-qrcode"></i>
                              </span>
                              Tạo QR
                            </div>
                            {/* <div className="button-cent one-col style3" onClick={this.showPopupDelete}>
                              <span>
                                <i className="fas fa-trash-alt"></i>
                              </span>
                              Xóa
                            </div> */}
                            <div className="button-cent one-col" onClick={this.showEdit}>
                              <span>
                                <i className="fas fa-pen"></i>
                              </span>
                              Sửa
                            </div>
                          </div>
                          :
                          <div className="action">
                            <div className="button-cent one-col style4" onClick={this.hideEdit}>
                              <span>
                                <i className="fas fa-times"></i>
                              </span>
                              Hủy
                            </div>
                            <div className={`button-cent one-col ${this.state.isDisabledSave ? "disabledbutton" : ""}`} onClick={this.saveEditCustomer} >
                              <span>
                                <i className="fas fa-save"></i>
                              </span>
                              Lưu
                            </div>
                          </div>
                        : null}
                    </div>
                    {Object.keys(this.state.detailCustomer).length !== 0 ?
                      <div className="body-col">
                        <div className="customer-block-details">
                          <div className="customer-avatar" onClick={() => this.setShowImgCustomer(this.state.detailCustomer.avata_url ? true : false)}>
                            {this.state.detailCustomer.avata_url ?
                              <img src={this.state.detailCustomer.avata_url} alt="" />
                              : <Avatar name={this.state.detailCustomer.full_name} size={(window.innerWidth > 768) ? "100" : "70"} textSizeRatio={1.75} round="50%" />}
                          </div>
                          <div className="customer-basic-info">
                            {this.state.showEdit ?
                              <input onChange={this.onChangeEditName} className="required" defaultValue={this.state.editName} type="text" />
                              : 
                              <div className="customer-name">
                                {this.state.detailCustomer.full_name}
                                {this.state.detailCustomer.identifier === 1 ? <span className="identifier-customer"><img src="/icons/verified.png" /></span> : ""}
                              </div>
                              }

                            <div className="date-create">
                              Khởi tạo lúc: {this.convertDateTime(this.state.detailCustomer.created_at, 'createdAt')}
                            </div>
                            <div className="last-visited">
                              Ghé thăm lần cuối: {this.state.lastOrder != '' ? convert.convertDateTime(this.state.lastOrder) : 'chưa đến'}
                            </div>
                            {/* {this.state.showEdit ?
                              <Select className="select half" isSearchable={false} onChange={this.onChangeEditRanking} styles={this.customStylesSelect} defaultValue={this.state.optionsRanking[this.state.editRanking - 1]} options={this.state.optionsRanking} />
                              : <div className={'rank-' + this.state.detailCustomer.ranking + ' ranking'}>{this.getLabelSelectDropdown(this.state.detailCustomer.ranking, this.state.optionsRanking)}</div>} */}
                              <div className={'rank-' + this.state.detailCustomer.ranking + ' ranking'}>{this.getLabelSelectDropdown(this.state.detailCustomer.ranking, this.state.optionsRanking)}</div>
                          </div>
                          <div className="customer-loyaty" onClick={() => { this.handelEditCredit(0) }}>
                            <div className="point">
                              <span className="edit-credit"><i className="fas fa-tools"></i></span>
                            </div>
                            <div className="title">Thao tác thẻ</div>
                          </div>
                        </div>
                        <span className="button-edit" onClick={() => this.setShowImgCustomer(this.state.detailCustomer.avata_url ? true : false)}><i className="fas fa-pencil-alt"></i></span>
                        <div className="booking-rate">
                          <div className="item-booking">
                            <div className="title-col col-4">
                              <div className="label w-700">
                                Tổng số lần đặt trước
                              </div>
                            </div>
                            <div className="data-col col-8">
                              <div className="wrap-bar">
                                <div
                                  className="bar total-booking-bar bg-green"
                                  style={{ width: "1%" }}
                                ></div>
                              </div>
                              <div className="count">1 (lần)</div>
                            </div>
                          </div>
                          <div className="item-booking">
                            <div className="title-col col-4">
                              <div className="label w-700">
                                Tổng số lần đến trực tiếp
                              </div>
                            </div>
                            <div className="data-col col-8">
                              <div className="wrap-bar">
                                <div className="bar total-booking-bar bg-blue"></div>
                              </div>
                              <div className="count">0 (lần)</div>
                            </div>
                          </div>
                          <div className="item-booking">
                            <div className="title-col col-4">
                              <div className="label w-700">
                                Tổng số lần hủy đặt / không đến
                              </div>
                            </div>
                            <div className="data-col col-8">
                              <div className="wrap-bar">
                                <div
                                  className="bar total-booking-bar bg-red"
                                  style={{ width: "8%" }}
                                ></div>
                              </div>
                              <div className="count">8 (lần)</div>
                            </div>
                          </div>
                          {this.state.totalOwedOrder != 0 ?
                            <div className="col-xs-12 text-danger-400 ng-binding item-owed" onClick={this.handelTransaction}>
                              <i className="fas fa-money"></i>Số tiền còn nợ: <strong className="text-danger ng-isolate-scope" >
                                <span className="cash-vnd text-danger">{convert.convertCurrency(this.state.totalOwedOrder)}</span>
                              </strong>
                              <i className="fas fa-pencil-alt"></i>
                            </div>
                          : null}
                          <div className="item-credit item-booking">
                            <div className="title-col col-3">
                              <div className="label">Số buổi gội được tặng: {this.state.dataHeadWashing.countUsed}/{this.state.dataHeadWashing.maxUsed}</div>
                            </div>
                            <div className="data-col col-9">
                              <div className="value w-700">
                                <span className="show-history" onClick={() => { this.handelHistoryTransferPackage() }}>Lịch sử chuyển nhượng thẻ</span>
                              </div>
                            </div>
                          </div>
                          {type != "crm" &&
                            <>
                              <div className="item-credit item-booking">
                                <div className="title-col col-3">
                                  <div className="label">Tiền trong tài khoản: </div>
                                </div>
                                <div className="data-col col-9">
                                  <div className="value w-700">
                                    {convert.convertCurrency(this.state.detailCustomer.deposit_money)}
                                    {/* <span className="edit-credit" onClick={() => { this.handelEditCredit(0) }}><i className="fas fa-pencil-alt"></i> Sửa</span> */}
                                    <span className="show-history" onClick={() => { this.handelHistoryCredit(1) }}>Xem Lịch sử</span>
                                  </div>
                                </div>
                              </div>
                              <div className="item-credit item-booking">
                                <div className="title-col col-3">
                                  <div className="label">Điểm tích luỹ: </div>
                                  
                                </div>
                                <div className="data-col col-9">
                                  <div className="value w-700">
                                    {convert.convertCurrencyLoyalty(this.state.detailCustomer.gift_money)}
                                    {this.state.detailCustomer.isMember != 1 ? <span style={{fontSize: "10px", color: "#5e6d8c", paddingLeft: "10px"}}>(chưa được sử dụng)</span> : ''}
                                    <span className="show-history" onClick={() => { this.handelHistoryCredit(2) }}>Xem Lịch sử</span>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="item-credit item-booking">
                                <div className="title-col col-3">
                                  <div className="label">Tiền trong ví triệt: </div>
                                  
                                </div>
                                <div className="data-col col-9">
                                  <div className="value w-700">
                                    {convert.convertCurrencyLoyalty(this.state.detailCustomer.wallet_package_money)}
                                    <span className="show-history" onClick={() => { this.handelHistoryCredit(3) }}>Xem Lịch sử ví triệt</span>
                                  </div>
                                </div>
                              </div> */}
                              {/* <div className="item-credit item-booking">
                                <div className="title-col col-3">
                                  <div className="label">Tiền quà tặng: </div>
                                </div>
                                <div className="data-col col-9">
                                  <div className="value w-700">
                                    {convert.convertCurrency(this.state.detailCustomer.gift_money)}
                                    <span className="edit-credit" onClick={() => { this.handelEditCredit(1) }}><i className="fas fa-pencil-alt"></i> Sửa</span>
                                    <span className="show-history" onClick={() => { this.handelHistoryCredit(1) }}>Xem Lịch sử</span>
                                  </div>
                                </div>
                              </div> */}
                            </>
                          }
                          <div className="item-booking list-stores">
                            <div className="title-col col-3">
                              <div className="label">Nguồn giới thiệu:</div>
                            </div>
                            <div className="data-col col-9">
                              <div className="value w-700">
                                {this.state.showEdit ?
                                  <input onChange={this.onChangeEditReferralSource} defaultValue={this.state.editReferralSource} type="text" />
                                  : this.state.detailCustomer.referral_source}
                              </div>
                            </div>
                          </div>
                          <div className="item-booking list-stores">
                            <div className="title-col col-3">
                              <div className="label">Chi nhánh</div>
                            </div>
                            <div className="data-col col-9">
                              {this.state.showEdit ?
                                <Select className="select"
                                  styles={this.customStylesMultiSelect}
                                  isMulti
                                  closeMenuOnSelect={false}
                                  onChange={this.onChangeEditStores}
                                  options={this.state.dataStore}
                                  defaultValue={
                                    this.state.currentStores.map((val, key) => (this.state.dataStore[val]))
                                  } />
                                : <div className="value w-700">
                                  {this.state.currentStores.map((val, key) => <span key={key}>{this.state.dataStore[val].label}</span>)}
                                </div>}
                            </div>
                          </div>
                        </div>
                        <div className="customer-more-info">
                          <div className="tabs-title" >
                            {tabsCustomer.map((x, i) => {
                              return (
                                <div onClick={() => { this.hanldeChooseTab(i) }} className={`item-tab ${x.selected ? "active" : ""}`} key={i}>{x.value}</div>
                              )
                            })}
                          </div>
                          <div className="tabs-data">
                            {this.state.chooseTab === 0 &&
                              <div className="items-tab active">
                                <div className="item">
                                  <div className="label w-700">Định danh</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <Checkbox checked={this.state.identifier === 1} onChange={this.onChangeIdentifier}>Có</Checkbox>
                                    : 
                                    this.state.identifier === 1 ? "Đã định danh" : "Chưa định danh"
                                    }
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Mã khách hàng</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditCode} defaultValue={this.state.editCode} type="text" />
                                      : this.state.detailCustomer.code}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Số điện thoại</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditMobile} defaultValue={this.state.editMobile} type="text" disabled={this.state.userAdmin.role === 1 ? false : true} />
                                      : this.state.detailCustomer.mobile}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Tài khoản Zalo</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditZaloAccount} defaultValue={this.state.editZaloAccount} type="text" />
                                      : this.state.detailCustomer.zalo_account}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Email</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditEmail} defaultValue={this.state.editEmail} type="text" />
                                      : this.state.detailCustomer.email}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Giới tính</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <div className="select-gender">
                                        <label className="radio-button">
                                          <input type="radio" value="2" name="gender" checked={this.state.editGender == 2} onChange={this.onChangeEditGender} />
                                          Chị
                                        </label>
                                        <label className="radio-button">
                                          <input type="radio" value="1" name="gender" checked={this.state.editGender == 1} onChange={this.onChangeEditGender} />
                                          Anh
                                        </label>
                                      </div>
                                      : this.state.detailCustomer.gender == 1 ? 'Nam' : 'Nữ'}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Facebook ID</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditFacebookId} defaultValue={this.state.editFacebookId} type="text" />
                                      : this.state.detailCustomer.facebook_id}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Pancake ID</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditPancakeId} defaultValue={this.state.editPancakeId} type="text" />
                                      : this.state.detailCustomer.pancake_id}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Ngày sinh</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <div className="select-birth">
                                        <Select className="select half data-birthday" onChange={this.onChangeEditDay} styles={this.customStylesSelect} isSearchable={false} defaultValue={this.state.optionsDays[this.state.editDay - 1]} options={this.state.optionsDays} />
                                        <Select className="select half data-birthday" onChange={this.onChangeEditMonth} styles={this.customStylesSelect} isSearchable={false} defaultValue={this.state.optionsMonths[this.state.editMonth - 1]} options={this.state.optionsMonths} />
                                        <div className="data-birthday"><input onChange={this.onChangeEditYear} defaultValue={this.state.editYear} type="text" /></div>
                                      </div>
                                      : this.state.detailCustomer.birthday}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">CCCD</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditIdCard} defaultValue={this.state.editIdCard} type="text" />
                                      : this.state.detailCustomer.id_card}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Tỉnh/Thành</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <Select className="select data-birthday location"
                                        placeholder="Tỉnh/Thành phố"
                                        isSearchable={true}
                                        onChange={this.onChangeEditCity}
                                        styles={this.customStylesSelect}
                                        defaultValue={location[this.state.keyCity]}
                                        options={location} />
                                      : this.state.detailCustomer.city}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Quận/huyện</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <Select className="select data-birthday location"
                                        ref={ref => { this.selectDistrictRef = ref }}
                                        placeholder="Quận/Huyện"
                                        isSearchable={true}
                                        onChange={this.onChangeEditDistrict}
                                        styles={this.customStylesSelect}
                                        defaultValue={this.state.dataDistrict[this.state.keyDistrict]}
                                        options={this.state.dataDistrict} />
                                      : this.state.detailCustomer.district}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Địa chỉ</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditAddress} defaultValue={this.state.editAddress} type="text" />
                                      : this.state.detailCustomer.address}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Nghề nghiệp</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditJob} defaultValue={this.state.editJob} type="text" />
                                      : this.state.detailCustomer.job}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Công ty</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditCompany} defaultValue={this.state.editCompany} type="text" />
                                      : this.state.detailCustomer.company}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Quốc gia</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <input onChange={this.onChangeEditCountry} defaultValue={this.state.editCountry} type="text" />
                                      : this.state.detailCustomer.country}
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              this.state.chooseTab === 1 &&
                              <div className="items-tab active">
                                <div className="item">
                                  <div className="label w-700">Ghi chú</div>
                                  <div className="value">
                                    {this.state.showEdit ?
                                      <textarea onChange={this.onChangeEditNote} value={this.state.editNote} style={{ width: "100%" }} rows={4}></textarea>
                                      : this.state.editNote ? this.state.editNote : "Không có gì"}
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              this.state.chooseTab === 2 &&
                              <div className="items-tab active">
                                <div className="item">
                                  <div className="label w-700">Tên</div>
                                  <div className="value"></div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Số điện thoại</div>
                                  <div className="value"></div>
                                </div>
                                <div className="item">
                                  <div className="label w-700">Vai trò</div>
                                  <div className="value"></div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      : ""}
                    {type != "crm" && this.state.userAdmin && this.state.userAdmin.role != 4 ?
                      Object.keys(this.state.detailCustomer).length !== 0 ?
                        <div className="bottom-col">
                          <div className="action">
                            {/* <div className="button-cent">
                              <span>
                                <i className="fas fa-print"></i>
                              </span>
                              In phiếu
                            </div> */}
                            <div className="button-cent style3">
                              <Link to={{ pathname: "/pos", search: "?customerId=" + this.state.detailCustomer.id }}>
                                <span>
                                  <i className="fas fa-clipboard-list"></i>
                                </span>
                                Tạo đơn hàng
                              </Link>
                            </div>

                            {/* <div className="button-cent">
                              <Link to={{ pathname: "/booking", search: "?action=create&cust_id=" + this.state.detailCustomer.id }}>
                                <span>
                                  <i className="far fa-calendar-alt"></i>
                                </span>
                                Đặt lịch
                              </Link>
                            </div> */}
                            <div className="button-cent" onClick={this.handlePopupAddBooking}>
                                <span>
                                  <i className="far fa-calendar-alt"></i>
                                </span>
                                Đặt lịch
                            </div>
                            {/* <div className="button-cent">
                              <span>
                                <i className="fas fa-signal"></i>
                              </span>
                              Điểm
                            </div> */}
                          </div>
                        </div>
                        : null
                      : null}
                  </div>
                  <CustomerRightBar
                    listPackage={this.state.listPackage}
                    customer={this.state.detailCustomer}
                    changeCurrenDeposit={this.changeCurrenDeposit}
                    saveEditCredit={this.saveEditCredit}
                    type={type} />
                </div>
              </div>
            </div>
            {this.state.showAdd ?
              <AddCustomer show={this.state.showAdd}
                close={this.hidePopupAdd}
                optionsDays={this.state.optionsDays}
                optionsMonths={this.state.optionsMonths}
                optionsRanking={this.state.optionsRanking}
                addCustomer={this.addCustomer}
                click={this.state.click}
                hanldeClick={this.hanldeClick}
                hanldeClickTrue={this.hanldeClickTrue}
              >
              </AddCustomer>
            : null}

            {this.state.showImgCustomer ?
              <ViewImgCustomer
              show={this.state.showImgCustomer}
              close={this.setShowImgCustomer}
              customer={this.state.detailCustomer}
              changeCurrenAvatar={this.changeCurrenAvatar}
              >
              </ViewImgCustomer>
            : null}
            {this.state.showDelete ? 
              <Delete show={this.state.showDelete}
                deleteCustomer={this.deleteCustomer}
                close={this.hidenPopupDelete}>
              </Delete>
            : null}

            {this.state.showFilterExport ? 
              <FilterExportData
                show={this.state.showFilterExport}
                close={this.hideFilterExport}
                handleExportDataCustomer={this.handleExportDataCustomer}>
              </FilterExportData>
            : null}

            {this.state.editCredit ?
              this.state.currentStore == 8 ?
                <EditCredit2
                  show={this.state.editCredit}
                  typeCredit={this.state.typeCredit}
                  changeCurrenDeposit={this.changeCurrenDeposit}
                  detailCustomer={this.state.detailCustomer}
                  listPackage={this.state.listPackage}
                  onHandelPopup={this.handelEditCredit}
                  saveEditCredit={this.saveEditCredit} />
              :
              <EditCredit
                show={this.state.editCredit}
                typeCredit={this.state.typeCredit}
                changeCurrenDeposit={this.changeCurrenDeposit}
                detailCustomer={this.state.detailCustomer}
                listPackage={this.state.listPackage}
                onHandelPopup={this.handelEditCredit}
                saveEditCredit={this.saveEditCredit} />
            : null}
            {this.state.addBooking ?
              <Add show={this.state.addBooking}
                isNewCustomer={this.state.isNewCustomer}
                newPhone={this.state.newPhone}
                newFullName={this.state.newFullName}
                newGender={this.state.detailCustomer.gender}
                close={this.handlePopupAddBooking}
                optionsGender={optionsGender}
                optionsHour={optionsHoursBooking}
                optionsMinute={optionsMinuteBooking}
                optionsDays={optionsDays}
                optionsMonths={optionsMonths}
                dataStore={this.state.dataStore}
                dataService={this.state.dataService}
                dataOperators={this.state.dataOperators}
                quickSetUser={this.state.quickSetUser}
                quickSetDate={this.state.quickSetDate}
                customerId={this.state.detailCustomer.id}
                getServiceByCustomer={this.getServiceByCustomer}
                addBooking={this.addBooking}
                handleDisableButtonAdd={this.handleButtonBooking}
                disableButtonAdd={this.state.disableButtonAdd}
                typeHandleInfoCustomer= {false}
              />
            : null}

            {this.state.historyCredit ?
              <HistoryCredit
                dataHistoryCredit={this.state.dataHistoryCredit}
                show={this.state.historyCredit}
                onHandelPopup={this.handelHistoryCredit}>
              </HistoryCredit>
            : null}

            {this.state.displayQrCode ? 
              <QRCode currentQrCode={null} customerId={this.state.detailCustomer.id} closePopupQRCode={(e) => {this.setState({ displayQrCode: e })}} />
            :null}

            {this.state.historyTransferPackage ?
              <HistoryTransferPackage
                dataHistoryTransferPackage={this.state.dataHistoryTransferPackage}
                show={this.state.historyTransferPackage}
                onHandelPopup={this.handelHistoryTransferPackage}>
              </HistoryTransferPackage>
            : null}
            
            {this.state.showTransaction ?
              <div className="order-popup package-history">
                <div className="popup-cent">
                  <div className="content-popup fadeInDown package-popup">
                    <TransactionPoup
                      customer={this.state.detailCustomer}
                      show={this.state.showTransaction}
                      getDataTransaction={this.getDataTransaction}
                      setShow={this.hiddenTransaction}
                      setDataCustomer={this.setCustomerData}
                      isCustomerPage={true}
                    ></TransactionPoup>

                    <div className="bottom-popup">
                      <div className="button-container">
                        <div onClick={this.hiddenOrderPopup} className="button-cent style3">
                          <span><i className="fas fa-times"></i></span>Đóng
                        </div>
                      </div>
                    </div>
                  </div>
                  <div onClick={this.hiddenOrderPopup} className="overlay"></div>
                </div>
              </div>
            : null}
          </div>
        </div>
      );
    } else return null;
  }
  
}
export default Customer;