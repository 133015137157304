import React, { useEffect, useState } from 'react'
import { Modal, Checkbox, CheckboxGroup, Button } from 'rsuite'
import './scss/cards.scss'
import axiosService from "../../utils/axios.service";
import { event } from 'jquery';
import { optionsTimeCard, optionsHourLast, optionsMinus, optionWaxing } from './constant/TimeUsingCard'
import Select from "react-select";
import CurrencyInput from 'react-currency-input-field';
import { notifications } from "../constant/Notify";
let _ = require('lodash');

const cardTypes = [
	{
		type: 1,
		text: "Thẻ số lần"
	},
	{
		type: 2,
		text: "Thẻ số tiền",
	}
]

const Edit = ({ open, setOpen, detailCard, editPackage, products, listSelectGroup, listGroup}) => {
	const [cardType, setCardType] = useState(Number(detailCard.meta_object.card_type))
	const [productName, setProductName] = useState(detailCard.product_name)
	const [price, setPrice] = useState(detailCard.price)
	const [basePrice, setBasePrice] = useState(detailCard.base_price)
	const [categoryId, setCategoryId] = useState(detailCard.category_id)
	const [description, setDescription] = useState(detailCard.description)
	const [status, setStatus] = useState(detailCard.status)
	const [type, setType] = useState(detailCard.type)
	const [maxUsed, setMaxUsed] = useState(detailCard.meta_object.max_used)
	const [createCardType, setCreateCardType] = useState(detailCard.meta_object.card_type)
	const [statusEditPrice, setStatusEditPrice] = useState(detailCard.meta_object.status_edit_price == "2" ? true : false)
	const [typeUsing, setTypeUsing] = useState(detailCard.type_using == 1 ? true : false)
	const [comServe, setComServe] = useState(detailCard.com)
	const [useTimeMonth, setUseTimeMonth] = useState(detailCard.meta_object.use_time_month)
	const [cardAccount, setCardAccount] = useState(detailCard.meta_object.card_account)
	const [productNameE, setProductNameE] = useState(detailCard.product_name_e)
	const [usedInfinite, setUsedInfinite] = useState(detailCard.meta_object.max_used >= 999999)
	const [timeUsing, setTimeUsing] = useState(detailCard.meta_object.time_using)
	const [rangeDate, setRangeDate] = useState(detailCard.range_date)
	const [productId, setProductId] = useState("")
	const [upgradeId, setUpgradeId] = useState("")
	const [giftPackageId, setGiftPackageId] = useState("")
	const [defaultValueProduct, setDefaultValueProduct] = useState(null)
	const [defaultValueUpgrade, setDefaultValueUpgrade] = useState(null)
	const [defaultValueCategory, setDefaultValueCategory] = useState(null)
	const [defaultValueGiftPackage, setDefaultValueGiftPackage] = useState(null)
	const [lastHour, setLastHour] = useState(optionsHourLast[12].value)
	const [lastMinus, setLastMinus] = useState(optionsMinus[0].value)
	const [checkKey, setCheckKey] = useState(12)
	const [countVoucherUse, setCountVoucherUse] = useState(detailCard.count_voucher_use ?detailCard.count_voucher_use: 0)
	const [productUsingIds, setProductUsingIds] = useState("")
	const [defaultProductUsingId, setDefaultProductUsingId] = useState(null)
	const [typeSell, setTypeSell] = useState((detailCard.open_sell === 0) ? true : false)
	const [maxUseChild, setMaxUseChild] = useState((detailCard.max_use_child === 0) ? false : true)
	const [newWaxing, setNewWaxing] = useState(() => {
		const filter = _.find(optionWaxing, function(o) { return o.value == detailCard.new_waxing })
		return filter
	})
	
	var onChangeProductName = (event) => {
		setProductName(event.target.value)
	};

	var onChangeProductNameE = (event) => {
		setProductNameE(event.target.value)
	};

	var onChangePrice = (event) => {
		let dataPrice = event.target.value.replaceAll(",", "");
		setPrice(dataPrice)
	};

	var clickStatusCardType = (val) => {
		// setCardType(val)
		// setCreateCardType(val)
	};

	useEffect(() => {
		const findProduct = detailCard.meta.findIndex(x => {
			return x.meta_key == "product_id"
		})
		if (findProduct > -1) {
			setProductId(detailCard.meta[findProduct].meta_value)
			const findIndex = products.findIndex(x => {
				return x.value == detailCard.meta[findProduct].meta_value
			})
			const value = findIndex > -1 ? products[findIndex] : null
			setDefaultValueProduct(value)
		}

		const findProductUpgrade = detailCard.meta.findIndex(x => {
			return x.meta_key == "upgrade_id"
		})
		if (findProductUpgrade > -1) {
			let optionList = []
			let listProductUpgrade = detailCard.meta[findProductUpgrade].meta_value
			let arrayId = listProductUpgrade.split(',');
			arrayId = arrayId.map(Number);
			setUpgradeId(listProductUpgrade)
			products.map(x => {
				if (arrayId.includes(x.value)) optionList.push(x)
			})
			setDefaultValueUpgrade(optionList)
		}
		const findProductUsingId = detailCard.meta.findIndex(x => {
			return x.meta_key == "product_using_ids"
		})
		if (findProductUsingId > -1) {
			let optionListUsing = []
			let listProductUsing = detailCard.meta[findProductUsingId].meta_value
			let arrayId = listProductUsing.split(',');
			arrayId = arrayId.map(Number);
			setProductUsingIds(listProductUsing)
			products.map(x => {
				if (arrayId.includes(x.value)) optionListUsing.push(x)
			})
			setDefaultProductUsingId(optionListUsing)
		}

		var checkKey = _.findIndex(optionsHourLast, function (o) { return o.value == detailCard.meta_object.last_hour });
		if (checkKey && typeof checkKey != 'undefined' && typeof optionsHourLast[checkKey] != 'undefined' && typeof optionsMinus[detailCard.meta_object.last_minute / 5] != 'undefined') {
			setLastHour(optionsHourLast[checkKey].value)
			setLastMinus(optionsMinus[detailCard.meta_object.last_minute / 5].value)
			setCheckKey(checkKey)
		}

		if (detailCard.category_id) {
			var checkKeyC = _.findIndex(listSelectGroup, function (o) { return o.value == detailCard.category_id });
			setDefaultValueCategory(listSelectGroup[checkKeyC])
		}

		const findGiftPackage = detailCard.meta.findIndex(x => {
			return x.meta_key == "gift_package"
		})
		if (findGiftPackage > -1) {
			let optionListGiftPackage = []
			let listGiftPackage = detailCard.meta[findGiftPackage].meta_value
			let arrayId = listGiftPackage.split(',');
			arrayId = arrayId.map(Number);
			setGiftPackageId(listGiftPackage)
			products.map(x => {
				if (arrayId.includes(x.value)) optionListGiftPackage.push(x)
			})
			setDefaultValueGiftPackage(optionListGiftPackage)
		}
	}, [detailCard, products])

	var onChangeStatusEditPrice = (event) => {
		setStatusEditPrice(!statusEditPrice)
	};
	var onChangeTypeUsing = (event) => {
		setTypeUsing(!typeUsing)
	};

	var onChangeBasePrice = (event) => {
		let dataBasePrice = event.target.value.replaceAll(",", "");
		setBasePrice(dataBasePrice)
	};

	var onChangeDescription = (event) => {
		setDescription(event.target.value)
	};

	var onMaxUsed = (event) => {
		if (event.target.value >= 999999) {
			setUsedInfinite(true)
		} else {
			setUsedInfinite(false)
		}
		setMaxUsed(event.target.value)
	}

	var onChangeUsedInfinite = (value, check) => {
		setUsedInfinite(check)
		if (check) {
			setMaxUsed(999999)
		} else {
			setMaxUsed(0)
		}
	};

	var onChangeCardAccount = (event) => {
		setCardAccount(event.target.value)
	}

	var onChangeUseTimeMonth = (event) => {
		setUseTimeMonth(event.target.value)
	}

	var onChangeComServe = (event) => {
		setComServe(event.target.value)
	}

	var onChangeTimeCard = (event) => {
		setTimeUsing(event.value)
	}

	var handlDefaultTimeCart = (timeUsing) => {
		var select = 0
		optionsTimeCard.map((val, key) => {
			if (val.value == timeUsing) {
				select = key
			}
		})
		return select
	}

	const addNewCart = () => {
		let StatusEditPrice = (statusEditPrice === true) ? 2 : 1
		let type_using = 0
		if (typeUsing) {
			type_using = 1
		}
		const dataServices = {
			"product_name": productName,
			"price": price,
			"base_price": basePrice,
			"category_id": categoryId,
			"description": description,
			"status": status,
			"type": 2,
			"product_name_e": productNameE,
			"range_date": rangeDate,
			"count_voucher_use": countVoucherUse,
			"type_using": type_using,
			"open_sell": (typeSell) ? 0 : 1,
			"max_use_child": (maxUseChild) ? 1 : 0,
			"com": comServe,
			"new_waxing": newWaxing?.value ?? 0,
			"meta_object": {
				"max_used": maxUsed,
				"card_type": createCardType,
				"status_edit_price": StatusEditPrice,
				"use_time_month": useTimeMonth,
				"card_account": cardAccount,
				"time_using": timeUsing,
				"product_id": productId,
				"last_hour": lastHour,
				"last_minute": lastMinus,
				"upgrade_id": upgradeId,
				"gift_package": giftPackageId,
				"product_using_ids": productUsingIds
			}
		}
		if (!productName || maxUsed == 0) {
			notifications({
				type: "error",
				mess: "Vui lòng điển đủ thông tin vào ô tô đậm",
			});
			return
		}
		editPackage(detailCard.id, dataServices)
		setOpen(false)
	}

	const onSelectProduct = (e) => {
		const value = e ? e.value : ""
		setProductId(value)
	}

	const onSelectGiftPackage = (e) => {
		let value = _.map(e, "value")
		setGiftPackageId(value)
	}

	const onSelectUpgrade = (e) => {
		let value = _.map(e, "value")
		setUpgradeId(value.join(','))
	}

	const onSelectProductUsingIds = (e) => {
		let value = _.map(e, "value")

		setProductUsingIds(value.join(','))
	}

	const onChangeLastHour = (event) => {
		setLastHour(event.value)
	}

	const onChangeLastMinus = (event) => {
		setLastMinus(event.value)
	}

	const onSelectCategory = (event) => {
		setCategoryId(event.value)
		if (event.value == 5) {
			setNewWaxing(optionWaxing[2])
		} else {
			setNewWaxing(optionWaxing[0])
		}
	}

	const onSelectNewWaxing = (event) => {
		setNewWaxing(event)
	}

	const onChangeUseVoucherCode = (event) => {
		setCountVoucherUse(event.target.value)
	}
	return (
		<>
			<Modal size="lg" open={open} onClose={() => setOpen(!open)}>
				<Modal.Header className="modal-header">
					<Modal.Title>Sửa thẻ dịch vụ</Modal.Title>
				</Modal.Header>

				<Modal.Body className="modal-body add-modal-card">
					<form>
						<div className="form-item-wrapper">
							<div className="flex justify-between">
								<label className="control-label col-3 ng-binding">Tên thẻ</label>
								<input onChange={onChangeProductName} defaultValue={detailCard.product_name} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Tên thẻ" />
							</div>
							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Tên tiếng anh</label>
								<input onChange={onChangeProductNameE} defaultValue={detailCard.product_name_e} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Nhập thẻ tên tiếng anh" />
							</div>
							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Mô tả chức năng thẻ</label>
								<textarea onChange={onChangeDescription} defaultValue={detailCard.description} className="form-control input-xs ng-pristine ng-untouched ng-valid ng-empty" placeholder="Nhập thẻ tên tiếng anh" />
							</div>
							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Giá bán thẻ</label>
								<div className="flex w-100">
									<CurrencyInput
										className="tr price-input required"
										placeholder="Nhập giá thẻ"
										defaultValue={detailCard.price}
										groupSeparator={','}
										decimalSeparator={'.'}
										onChange={onChangePrice}
									/>
									<div className="flex align-center">
										<Checkbox checked={statusEditPrice} onChange={onChangeStatusEditPrice}>Sửa giá và số lần thẻ khi thanh toán</Checkbox>
									</div>
								</div>
							</div>

							<div className="flex mt-10 justify-between">
								<label className="control-label col-3 ng-binding">Loại thẻ</label>
								<div className="flex justify-between w-100">
									{cardTypes.map((item) => (
										<div onClick={() => clickStatusCardType(item.type)} className={`card-type ${cardType === item.type ? 'active' : ''}`} key={item.type}>{item.text}</div>
									))}
								</div>
							</div>
							{cardType == 1 ?
								<div className="flex mt-10 justify-between">
									<label className="control-label col-3 ng-binding">Số lân sử dụng</label>
									<div className="flex w-100">
										<input onChange={onMaxUsed} defaultValue={detailCard.meta_object.max_used} className="tr price-input " />
										<div className="flex align-center">
											<Checkbox onChange={onChangeUsedInfinite} checked={usedInfinite}>Vô hạn</Checkbox>
										</div>
									</div>
								</div>
								:
								<div className="flex mt-10 justify-between">
									<label className="control-label col-3 ng-binding">Số tiền trong thẻ</label>
									<div className="flex w-100">
										<input onChange={onChangeCardAccount} defaultValue={detailCard.meta_object.card_account} className="ng-pristine ng-untouched ng-valid ng-empty" />
									</div>
								</div>
							}

							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Thời hạn thẻ</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<input defaultValue={detailCard.meta_object.use_time_month} onChange={onChangeUseTimeMonth} className="form-control w-20" placeholder="127" />
									<span className="input-group-addon ng-binding">tháng từ ngày cấp</span>
								</div>
							</div>
							{cardType == 1 ?
								<div className="flex mt-10 ">
									<label className="control-label col-3 ng-binding">Thời gian làm dịch vụ</label>
									<div className="flex gap-20 item-flex-start w-100 ">
										{optionsTimeCard[0] ? <Select
											isSearchable={false}
											className="select"
											defaultValue={optionsTimeCard[handlDefaultTimeCart(detailCard.meta_object.time_using)]}
											options={optionsTimeCard}
											onChange={onChangeTimeCard}
										/> : ""}
									</div>
								</div>
								: ""}
							<div className="flex mt-10">
							 	<label className="control-label col-3 ng-binding">Thời gian đặt lịch muộn nhất</label>
								<div className=" gap-20 item-flex-start w-100">
									<div className="row">
										<div className="col-6">
										<Select className="select" onChange={onChangeLastHour} defaultValue={optionsHourLast[checkKey]} isSearchable={true} options={optionsHourLast} />
										</div>
										<div className="col-6">
										<Select className="select" onChange={onChangeLastMinus} defaultValue={optionsMinus[detailCard.meta_object.last_minute / 5]} isSearchable={true} options={optionsMinus} />
										</div>
									</div>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Thời gian dãn cách</label>
								<div className="flex gap-20 item-flex-start w-50 ">
									<input type="number" className="form-control w-50" min={1} value={rangeDate} onChange={(e) => {
										setRangeDate(e.target.value)
									}} />
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Tặng voucher gội đầu</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<input onChange={onChangeUseVoucherCode} className="form-control w-20 tr price-input" defaultValue={countVoucherUse} placeholder="Sô lần sử dụng" />
									<span className="input-group-addon ng-binding">( Config số lần sử dụng )</span>
								</div>
							</div>

							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Buổi lẻ</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										isClearable={true}
										defaultValue={defaultValueProduct}
										isSearchable={true}
										onChange={onSelectProduct}
										options={products}
									/>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Buổi tặng</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										closeMenuOnSelect={false}
										isClearable={true}
										defaultValue={defaultValueGiftPackage}
										isSearchable={true}
										isMulti
										onChange={onSelectGiftPackage}
										options={products}
									/>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Nhóm dịch vụ</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										isClearable={true}
										defaultValue={defaultValueCategory}
										isSearchable={true}
										onChange={onSelectCategory}
										options={listSelectGroup}
									/>
								</div>
							</div>

							{categoryId === 5 ? 
								<div className="flex mt-10 ">
									<label className="control-label col-3 ng-binding">Loại thẻ triệt</label>
									<div className="flex gap-20 item-flex-start w-100 ">
										<Select
											className="basic-single w-100"
											classNamePrefix="select"
											isClearable={true}
											isSearchable={true}
											defaultValue={newWaxing}
											onChange={onSelectNewWaxing}
											options={optionWaxing}
										/>
									</div>
								</div>
							: null}
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Nhóm dịch vụ nâng cấp</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										closeMenuOnSelect={false}
										isClearable={true}
										defaultValue={defaultValueUpgrade}
										isSearchable={true}
										isMulti
										onChange={onSelectUpgrade}
										options={products}
									/>
								</div>
							</div>
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Thẻ con</label>
								<div className="flex gap-20 item-flex-start w-100 ">
									<Select
										className="basic-single w-100"
										classNamePrefix="select"
										closeMenuOnSelect={false}
										isClearable={true}
										defaultValue={defaultProductUsingId}
										isSearchable={true}
										isMulti
										onChange={onSelectProductUsingIds}
										options={products}
									/>
								</div>
							</div>
							{productUsingIds.length > 0 ?
								<>
									<div className="flex mt-10 ">
										<label className="control-label col-3 ng-binding">Kiểu sử dụng</label>
										<div className="flex align-center">
											<Checkbox checked={typeUsing} onChange={onChangeTypeUsing}>Sử dụng tính theo lần dịch vụ con</Checkbox>
										</div>
									</div>
									<div className="flex mt-10 ">
										<label className="control-label col-3 ng-binding">Số lần sử dụng của thẻ con</label>
										<div className="flex align-center">
											<Checkbox checked={typeUsing} onChange={onChangeTypeUsing}>Sử dụng tính theo lần dịch vụ con</Checkbox>
										</div>
									</div>
								</>
							:null}
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Mở bán</label>
								<div className="flex align-center">
									<Checkbox checked={typeSell} onChange={() => setTypeSell(!typeSell)}>Mở bán ?</Checkbox>
								</div>
							</div>
						</div>
						<div className="form-item-wrapper">
							<div className="flex mt-10 ">
								<label className="control-label col-3 ng-binding">Com phục vụ</label>
								<div>
									<div className="flex w-100">
										<input defaultValue={comServe} onChange={onChangeComServe} className="form-control w-50 ng-pristine ng-untouched ng-valid ng-empty" />
									</div>

								</div>
							</div>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer className="modal-footer">
					<Button onClick={() => setOpen(!open)} className="button-renew-card-modal">
						<i className="fa-solid fa-x"></i>
						Đóng
					</Button>
					<Button onClick={() => addNewCart()} className="button-save-card-modal">
						<i className="fa-solid fa-file-lines"></i>
						Lưu thông tin
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Edit