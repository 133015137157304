

export const customStylesBirthday = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 32,
      height: 32,
      paddingLeft: 0, 
      paddingRight: 0, 
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32
    }),
    placeholder: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32,
      lineHeight: '32px'
    }),
    input: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      height: 32
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      display: 'none'
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      const marginLeft = 0;
      const marginRight =  0; 
      const height = 32; 
      const lineHeight = '32px'
      return { ...provided, opacity, transition, marginLeft, marginRight, height, lineHeight };
    },
};

export const optionsDays = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
    { value: '13', label: '13' },
    { value: '14', label: '14' },
    { value: '15', label: '15' },
    { value: '16', label: '16' },
    { value: '17', label: '17' },
    { value: '18', label: '18' },
    { value: '19', label: '19' },
    { value: '20', label: '20' },
    { value: '21', label: '21' },
    { value: '22', label: '22' },
    { value: '23', label: '23' },
    { value: '24', label: '24' },
    { value: '25', label: '25' },
    { value: '26', label: '26' },
    { value: '27', label: '27' },
    { value: '28', label: '28' },
    { value: '29', label: '29' },
    { value: '30', label: '30' },
    { value: '31', label: '31' },
];

export const optionsMonths = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
]

export const optionsRanking = [
  { value: 1, label: 'Member' },
  { value: 2, label: 'Silver' },
  { value: 3, label: 'Gold' },
  { value: 4, label: 'Platinum' }
]

export const listStatusBooking = {
  1: 'Chưa xác nhận',
  2: 'Đã xác nhận',
  3: 'Chờ phục vụ',
  4: 'Đang phục vụ',
  5: 'Hủy lịch',
  6: 'Không đến',
  7: 'Hoàn thành'
}

export const listStatusOrder = {
  1 : 'Đang xử lý',
  2 : 'Đã huỷ',
  3 : 'Hoàn thành',
  4: 'Upsale'
}


export const listSourceBooking = {
  1:'Gọi điện',
  2:'FB Messenger',
  3:'Instagram',
  4:'Zalo',
  5:'Website'
}

export const PaymentType = [
  'Chuyển khoản',
  'Thẻ thành viên',
  'Nhiều loại',
  'Quẹt thẻ',
  'Tiền mặt'
]

export const PackageStatus = {
  1: 'Sử dụng',
  2: 'Đã khoá',
  3: 'Hết hạn',
  4: 'Hoàn thành',
  5: 'Hoàn tiền',
  6: 'Đã nâng cấp',
  7: "Đã hoàn ví triệt"
}

export  const filterByStatus = [
  { value: "Tiền mặt", label: "Tiền mặt" },
  { value: "Chuyển khoản", label: 'Chuyển khoản' },
  { value: "Quẹt thẻ", label: 'Quẹt thẻ' },
  { value: "Ship COD", label: 'Ship COD' },
  { value: "Ví điện tử", label: 'Ví điện tử' },
]

export  const  statusPay = {
  1:"Tiền mặt",
  2:'Quẹt thẻ',
  3: 'Ship COD',
  4: 'Ví điện tử'
}

export const tabsCustomer=[
  {value: " Thông tin cá nhân",selected:true},
  {value: " Ghi chú ",selected:false},
  // {value: " Người thân",selected:false},
]

export const reasonEditCredit = [
  { value: "Chuyển cọc", label: 'Chuyển cọc' },
  { value: "Chuyển đổi thẻ", label: 'Chuyển đổi thẻ' },
  { value: "Nhượng thẻ", label: 'Nhượng thẻ' },
  { value: "Nâng cấp thẻ buổi", label: 'Nâng cấp thẻ buổi' },
  { value: "Nâng cấp thẻ triệt", label: 'Nâng cấp thẻ triệt' },
  { value: "Hoàn tiền", label: 'Hoàn tiền' },
]

export const reasonEditCreditAdmin = [
  { value: "Quà tặng", label: "Quà tặng" },
  { value: "Cashback", label: "Cashback" },
  { value: "Chuyển cọc", label: 'Chuyển cọc' },
  { value: "Chuyển đổi thẻ", label: 'Chuyển đổi thẻ' },
  { value: "Nhượng thẻ", label: 'Nhượng thẻ' },
  { value: "Nâng cấp thẻ buổi", label: 'Nâng cấp thẻ buổi' },
  { value: "Nâng cấp thẻ triệt", label: 'Nâng cấp thẻ triệt' },
  { value: "Hoàn tiền", label: 'Hoàn tiền' },
]

export const reasonEditCredit2 = [
  { value: "Chuyển cọc", label: 'Chuyển cọc' },
  { value: "Chuyển đổi thẻ", label: 'Chuyển đổi thẻ' },
  { value: "Nhượng thẻ", label: 'Nhượng thẻ' },
  { value: "Nâng cấp thẻ buổi", label: 'Nâng cấp thẻ buổi' },
  { value: "Nâng cấp thẻ triệt", label: 'Nâng cấp thẻ triệt cũ' },
  { value: "Nâng cấp thẻ triệt premium", label: 'Nâng cấp thẻ triệt premium' },
  { value: "Hoàn tiền", label: 'Hoàn tiền' },
]

export const reasonEditCreditAdmin2 = [
  { value: "Quà tặng", label: "Quà tặng" },
  { value: "Cashback", label: "Cashback" },
  { value: "Chuyển cọc", label: 'Chuyển cọc' },
  { value: "Chuyển đổi thẻ", label: 'Chuyển đổi thẻ' },
  { value: "Nhượng thẻ", label: 'Nhượng thẻ' },
  { value: "Nâng cấp thẻ buổi", label: 'Nâng cấp thẻ buổi' },
  { value: "Nâng cấp thẻ triệt", label: 'Nâng cấp thẻ triệt cũ' },
  { value: "Nâng cấp thẻ triệt premium", label: 'Nâng cấp thẻ triệt premium' },
  { value: "Hoàn tiền", label: 'Hoàn tiền' },
]

export const reasonEditCreditPackage = [
  { value: "Chuyển đổi thẻ", label: 'Chuyển đổi thẻ' },
]

export const transferEditCreditPackage = [
  { value: "Nhượng thẻ", label: 'Nhượng thẻ' },
]

export const listHeadWaxing = [
  { value: "Nhượng thẻ", label: 'Nhượng thẻ' },
]