import React, { useState, useEffect, memo } from "react"
import { DateRangePicker, SelectPicker, Dropdown, Input, DatePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays, subWeeks, startOfWeek, endOfWeek, addWeeks, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import { Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import axiosService from "../../utils/axios.service";
import { format } from "date-fns"
import { Button } from 'antd';
import 'antd/dist/antd.min.css'
import headerReportEnum from "../../enums/headerReport.enum";
import { CSVDownload } from "react-csv";
import * as convert from "../constant/Convert.helper";
import _ from "lodash";


const Header = ({ startDate, endDate, hanldeRangeDate, store, hanldeStore, hanldeSearchVoucher, sortBy, hanldeSortBy,
    title, type, isExport, csvData, hanldeExportData, header, fileName, userAdmin, onChangeKeyword, startOldDate,  endOldDate, hanldeRangeDateOld}) => {
    const [locations, setLocations] = useState([])
    const [locationsWhidth, setLocationsWhidth] = useState(window.innerWidth)

    const [sortByList, setSortByList] = useState(['Ngày', "Tuần", 'Tháng'].map(
        (x, i) => ({ label: x, value: i })
    ))
    const [sortByListBooking, setSortByListBooking] = useState(['Ngày', "Tuần", 'Tháng'].map(
        (x, i) => ({ label: x, value: i })
    ))
    const [keyword, setKeyword] = useState('')
    let startOfWeek1 = startOfWeek(new Date())
    let endOfWeek1 = endOfWeek(new Date())
    console.log(startOldDate, endOldDate)
    let ranges = [
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Tuần này",
            value: [addDays(startOfWeek1, 1), addDays(endOfWeek1, 1)],
        },
        {
            label: "Tuần trước",
            value: [addDays(startOfWeek(addDays(startOfWeek1, -1)), 1), addDays(endOfWeek(addDays(startOfWeek1, -1)), 1)],
        },
        {
            label: "Tháng này",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
            label: "Tháng trước",
            value: [startOfMonth(addDays(startOfMonth(new Date()), -1)), endOfMonth(addDays(startOfMonth(new Date()), -1))],
        },
        {
            label: "Năm này",
            value: [startOfYear(new Date()), endOfDay(endOfYear(new Date()))],
        },
        {
            label: "Năm trước",
            value: [startOfYear(addDays(startOfYear(new Date()), -1)), endOfYear(addDays(startOfYear(new Date()), -1))],
        },
    ]
    let rangesNv = [
        {
            label: "Hôm qua",
            value: [
                startOfDay(addDays(new Date(), -1)),
                endOfDay(addDays(new Date(), -1)),
            ],
        },
        {
            label: "Hôm nay",
            value: [startOfDay(new Date()), endOfDay(new Date())],
        },
        {
            label: "Tuần này",
            value: [addDays(startOfWeek1, 1), addDays(endOfWeek1, 1)],
        },
        {
            label: "Tuần qua",
            value: [addDays(startOfWeek(addDays(startOfWeek1, -1)), 1), addDays(endOfWeek(addDays(startOfWeek1, -1)), 1)],
        },
        {
            label: "Tháng này",
            value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
            label: "Tháng qua",
            value: [startOfMonth(addDays(startOfMonth(new Date()), -1)), endOfMonth(addDays(startOfMonth(new Date()), -1))],
        },
    ]
    useEffect(() => {
        if (["daily", "ktv-tvv-detail",  "customers", "cashiers", "users", "products", "sales", "saless", "ktv-tvv", "detail-ktv-tvv"].includes(type)) {
            fetchLocations()
        }
        if (type == 'detail_promotion') {
            fetchGroupVoucher()
        }
    }, [sortBy])
    const onChangeDate = (e) => {
        if (e) {
            const eDate = format(e[1], 'yyyy-MM-dd')
            const sDate = format(e[0], 'yyyy-MM-dd')
            hanldeRangeDate(sDate, eDate)
        }
    }
    const onChangeDateOld = (e) => {
        if (e) {
            const eDate = format(e[1], 'yyyy-MM-dd')
            const sDate = format(e[0], 'yyyy-MM-dd')
            hanldeRangeDateOld(sDate, eDate)
        }
    }

    const fetchLocations = async () => {
        if (userAdmin && [3, 4].includes(userAdmin.role)) {
            let dataStore = convert.getStoreByRole(userAdmin)
            setLocations([...dataStore])
        } else {
            const rs = await axiosService("api/stores", "GET")
            const { data } = rs
            if (data.code === 200) {
                data.data.unshift({ name_store: "Tất cả", id: 0 })
                const newLocations = data.data.map(x => {
                    return { label: x.name_store, value: x.id }
                })
                setLocations([...newLocations])
            }
        }
    }

    const fetchGroupVoucher = async () => {
        const rs = await axiosService("api/category_voucher", "GET")
        const { data } = rs
        if (data.code === 200) {
            data.data.unshift({ group_name: "Tất cả", id: 0 })
            const newLocations = data.data.map(x => {
                return { label: x.group_name, value: x.id }
            })
            setLocations([...newLocations])
        }
    }
    const onChangeSortBy = (e) => {
        hanldeSortBy(e)
    }
    const onChangeVoucherName = _.debounce((e) => {
        if (e.length > 3) {
            hanldeSearchVoucher(e)
        }
    }, 1000)

    const onChangeKeywordHeader = _.debounce((e) => {
        if (e.length > 3 || e.length == 0) {
            setKeyword(e)
            onChangeKeyword(e)
        }
    }, 1000)
    const onChangeStore = (e) => {
        hanldeStore(e)
    }
    const hanldeExport = () => {
        hanldeExportData()
    }

    return (
        <div className="search-report pl-2 pr-2">
            <Row>
                {isExport && <CSVDownload data={csvData} headers={header} filename={fileName} target="_blank" />}
                {type != 'detail-ktv-tvv' && 
                    <Col xs={type != 'locations' ? 12 : 6} md={4} className="mb-2">
                       {locationsWhidth > 765 ?
                           <Dropdown title={title}>
                               {
                                   headerReportEnum.map((x, i) => {
                                       return (
                                           <Link to={{ pathname: `/report/${x.route}` }} key={i}>
                                               <Dropdown.Item >
                                                   <i className={`${x.icon} mr-1`}></i><span>{x.lable}</span> {type === x.route && <i className="ml-2 fa-solid fa-check"></i>}
                                               </Dropdown.Item>
                                           </Link>
                                       )
                                   })
                               }
                           </Dropdown>
                           :
                           <Row>
                               <Col xs={6}>
                                   <Dropdown title={title}>
                                       {
                                           headerReportEnum.map((x, i) => {
                                               return (
                                                   <Link to={{ pathname: `/report/${x.route}` }} key={i}>
                                                       <Dropdown.Item >
                                                           <i className={`${x.icon} mr-1`}></i><span>{x.lable}</span> {type === x.route && <i className="ml-2 fa-solid fa-check"></i>}
                                                       </Dropdown.Item>
                                                   </Link>
                                               )
                                           })
                                       }
                                   </Dropdown>
                               </Col>
                               <Col xs={6}>
                                   {["booking", "ktv-tvv-detail", "daily", "customers", "cashiers", "users", "products", "sales", "ktv-tvv", "detail_promotion", "saless", "detail-ktv-tvv"].includes(type) &&
                                       <SelectPicker data={locations} className="w-100" defaultValue={store} onChange={onChangeStore}
                                           cleanable={false}
                                       />
                                   }
                               </Col>
                           </Row>
                       }
                   </Col>
                }
                <Col xs={type != 'locations' ? 12 : 6} md={8}>
                    {locationsWhidth > 765 ?
                        <Row>
                            {
                                type == "ktv-tvv-detail" &&
                                <Col sm={3} xs={type != 'detail-ktv-tvv' || type != 'locations'  ? 6 : 10}>
                                    <DateRangePicker
                                      className="w-100"
                                      placement={"bottomEnd"}
                                      format="yyyy-MM-dd"
                                      onChange={onChangeDateOld}
                                      ranges={type != 'detail-ktv-tvv' ? ranges : rangesNv}
                                      cleanable={false}
                                      defaultValue={[new Date(startOldDate), new Date(endOldDate)]}
                                      value={[new Date(startOldDate), new Date(endOldDate)]}
                                    />
                                </Col>

                            }
                            <Col xs={type == "ktv-tvv-detail" ? 2: 3}  className="mb-2">
                                {["booking", "ktv-tvv-detail", "daily", "customers", "cashiers", "users", "products", "sales", "ktv-tvv", "detail_promotion", "saless"].includes(type) &&
                                    <SelectPicker data={locations} className="w-100" defaultValue={store} onChange={onChangeStore}
                                        cleanable={false}
                                    />
                                }
                            </Col>
                            {(type === "daily") &&
                                <Col xs={2}  className="mb-2">
                                    <SelectPicker data={sortByList} className="w-100" defaultValue={sortBy} onChange={onChangeSortBy} cleanable={false} />
                                </Col>
                            }

                            {type === "booking" &&
                                <Col xs={2}  className="mb-2">
                                    <SelectPicker data={sortByListBooking} className="w-100" defaultValue={sortBy} onChange={onChangeSortBy} cleanable={false} />
                                </Col>
                            }
                            {type === "detail_promotion" &&
                                <Col xs={6} md={2}  className="mb-2">
                                    <Input data={sortByList} placeholder="Chi tiết mã" className="w-100" onChange={onChangeVoucherName} cleanable={false} />
                                </Col>
                            }

                            {
                                (type == "sales" || type == "ktv-tvv" || type == "count_package_customer") &&
                                <Col xs={3}  className="mb-2">
                                    <Input data={keyword} placeholder="Tìm kiếm theo keyword" className="w-200" onChange={onChangeKeywordHeader} cleanable={false} />
                                </Col>
                            }
                            <Col xs={type != 'detail-ktv-tvv' ? 3 : 5}  className="mb-2">
                                <DateRangePicker
                                    className="w-100"
                                    placement={"bottomEnd"}
                                    format="yyyy-MM-dd"
                                    onChange={onChangeDate}
                                    ranges={type != 'detail-ktv-tvv' ? ranges : rangesNv}
                                    cleanable={false}
                                    defaultValue={[new Date(startDate), new Date(endDate)]}
                                />
                            </Col>
                            
                            {type != 'detail-ktv-tvv' &&
                                <Col xs={2} style={{ textAlign: "right" }}  className="mb-2">
                                    <Button size="middle" type="primary"
                                        onClick={hanldeExport}
                                    ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                                    </Button>
                                </Col>
                            }
                        </Row>
                        :
                        <Row>
                            {
                                type == "ktv-tvv-detail" &&
                                <Col sm={3} xs={type != 'detail-ktv-tvv' || type != 'locations'  ? 6 : 10}>
                                    <DateRangePicker
                                        className="w-100"
                                        placement={"bottomEnd"}
                                        format="yyyy-MM-dd"
                                        onChange={onChangeDateOld}
                                        ranges={type != 'detail-ktv-tvv' ? ranges : rangesNv}
                                        cleanable={false}
                                        style={{width: 350}}
                                        showOneCalendar={true}
                                        defaultValue={[new Date(startOldDate), new Date(endOldDate)]}
                                        value={[new Date(startOldDate), new Date(endOldDate)]}
                                    />
                                </Col>
                            }
                            {(type === "daily" || type === "ktv-tvv-detail") &&
                                <Col xs={5}>
                                    <SelectPicker data={sortByList} className="w-100" defaultValue={sortBy} onChange={onChangeSortBy} cleanable={false} />
                                </Col>
                            }
                            {type === "booking" &&
                                <Col xs={5}>
                                    <SelectPicker data={sortByList} className="w-100" defaultValue={sortBy} onChange={onChangeSortBy} cleanable={false} />
                                </Col>
                            }
                            {type === "detail_promotion" &&
                                <Col xs={4} md= {2}>
                                    <Input data={sortByList} placeholder="Chi tiết mã" className="w-100" onChange={onChangeVoucherName} cleanable={false} />
                                </Col>
                            }

                            {
                                (type == "sales" || type == "ktv-tvv") &&
                                <Col sm={4} xs={6}>
                                    <Input data={keyword} placeholder="Tìm kiếm theo keyword" className="w-200" onChange={onChangeKeywordHeader} cleanable={false} />
                                </Col>
                            }
                            <Col sm={4} xs={type != 'detail-ktv-tvv' || type != 'locations'  ? 6 : 10}>
                                <DateRangePicker
                                    className="w-100"
                                    placement={"bottomEnd"}
                                    format="yyyy-MM-dd"
                                    onChange={onChangeDate}
                                    ranges={type != 'detail-ktv-tvv' ? ranges : rangesNv}
                                    cleanable={false}
                                    style={{width: 350}}
                                    showOneCalendar={true}
                                    defaultValue={[new Date(startDate), new Date(endDate)]}
                                />
                            </Col>
                            
                            
                            {type != 'detail-ktv-tvv' &&
                                 <Col sm={3} xs={12} style={{ textAlign: "right" }}>
                                 <Button size="middle" type="primary"
                                     onClick={hanldeExport}
                                 ><i className="fa-solid fa-file-excel mr-2"></i>Xuất Excel
                                 </Button>
                             </Col>
                            }
                           
                        </Row>
                    }
                </Col>
            </Row>
        </div>
    )
}
export default memo(Header)